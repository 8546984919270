
<h2>Lista de Horários</h2>
<form (ngSubmit) = "onAdicionarHorario()" [formGroup]="fourthFormGroup">
  <mat-card>
    <h3>Cadastre um horário livre</h3>


    <!-- <mat-form-field class="trinta" *ngIf="!isHorarioInserir">
      <mat-label>Consultório</mat-label>
      <mat-select formControlName="consultorios" (click)="update()">
        <mat-option *ngFor="let cons of consultorios" [value]="cons.idConsultorio" name="consultorio">
          {{cons.nomeConsultorio}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="fourthFormGroup.controls['consultorios'].invalid">Escolha um consultório válido</mat-error>
    </mat-form-field> -->

    <!-- *ngIf="isHorarioInserir" -->
    <mat-form-field class="trinta" >
      <mat-label>Consultório</mat-label>
      <mat-select formControlName="consultorios" (click)="update()">
        <mat-option *ngFor="let cons of consultorios" [value]="cons.idConsultorio" name="consultorio">
          {{cons.nomeConsultorio}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="fourthFormGroup.controls['consultorios'].invalid">Escolha um consultório válido</mat-error>
    </mat-form-field>

    <mat-form-field class="trinta">
      <mat-label>Dia da Semana</mat-label>
      <mat-select formControlName="semana">
        <mat-option *ngFor="let dia of semana" [value]="dia.idDiaSemana" name="semana">
          {{dia.descricao}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="fourthFormGroup.controls['semana'].invalid">Escolha um dia válido</mat-error>
    </mat-form-field>
    <mat-form-field class="dez">
      <input matInput placeholder="Hora DE" aria-label="Hora DE" [ngxTimepicker]="with5Gap" [format]="24" formControlName="horaInicio" name="horaInicio" readonly >
      <ngx-material-timepicker #with5Gap [minutesGap]="5"></ngx-material-timepicker>
      <mat-error *ngIf="fourthFormGroup.controls['horaInicio'].invalid">Escolha uma hora válida</mat-error>
    </mat-form-field>
    <mat-form-field class="dez">
      <input matInput placeholder="Hora ATÉ" aria-label="Hora ATE" [ngxTimepicker]="with5Gap2" [format]="24" formControlName="horaFim" name="horaFim" readonly >
      <ngx-material-timepicker #with5Gap2 [minutesGap]="5"></ngx-material-timepicker>
      <mat-error *ngIf="fourthFormGroup.controls['horaFim'].invalid">Escolha uma hora válida</mat-error>
    </mat-form-field>
    <div class="dez">
      <button mat-button [disabled]="fourthFormGroup.invalid">Inserir</button>
    </div>
  </mat-card>
</form>

<mat-card>
  <h3>Horários disponíveis</h3>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="diaSemana">
      <th mat-header-cell *matHeaderCellDef > Dia da Semana </th>
      <td mat-cell *matCellDef="let element" >{{element.diaSemana.descricao}}</td>
    </ng-container>


    <ng-container matColumnDef="horaInicio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hora De </th>
      <td mat-cell *matCellDef="let element" >{{element.horaInicio}}</td>
    </ng-container>


    <ng-container matColumnDef="horaFim">
      <th mat-header-cell *matHeaderCellDef> Hora Até </th>
      <td mat-cell *matCellDef="let element" > {{element.horaFim}} </td>
    </ng-container>


    <ng-container matColumnDef="consultorios">
      <th mat-header-cell *matHeaderCellDef> Consultório </th>
      <td mat-cell *matCellDef="let element">{{element.consultorio.nomeConsultorio}}</td>
    </ng-container>


    <ng-container matColumnDef="excluir">
      <th mat-header-cell *matHeaderCellDef >Excluir</th>
      <td mat-cell *matCellDef="let element" [value]="element.idAgenda">
        <a (click)="openDialog(element.idAgenda)" class="delete">
          <i class="material-icons">
              delete
          </i>
        </a>
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</mat-card>

<!-- <div *ngIf="!isHorarioInserir">
  <button (click)="onEnviarHorario()" mat-button >Enviar</button>
</div>

<div *ngIf="isHorarioInserir">
  <button (click)="onEnviarHorarioCRUD()" mat-button >Enviar CRUD</button>
</div> -->
