import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginService } from '../login/login.service';
import { Usuario } from '../login/usuario';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';


@Component({
  selector: 'app-login-cadastro',
  templateUrl: './login-cadastro.component.html',
  styleUrls: ['./login-cadastro.component.css']
})
export class LoginCadastroComponent implements OnInit {

  username: string;
  password: string;
  errors: String[];
  cadastrando: boolean;
  mensagemSucesso: string;
  psicologo: Psicologo;
  usuario: Usuario;

  constructor(public loginService: LoginService,
    private router: Router,
    private authService: AuthService,
    private psicologoService: PsicologoService){
  }

  ngOnInit(): void {
  }

 onSubmit(){
  this.authService.logar( this.username, this.password )
                  .subscribe( response => {
                    const access_token = JSON.stringify(response);
                    localStorage.setItem('access_token', access_token);

                    this.loginService.getUserByToken(this.username).subscribe(response => {
                      this.usuario = response;
                      this.psicologoService.pessoa.idPessoa = this.usuario.idPessoa;
                      this.router.navigate(['/']).then(() => {
                      window.location.reload()});
                    });
                  }, errorResponse => {
                    this.errors = ['Usuário e/ou senha incorretos']
                  })
 }

 preparaCadastrar(event){
   event.preventDefault();
   this.cadastrando = true;
 }


 cancelaCadastro(){
   this.cadastrando = false;
   this.mensagemSucesso = null;
   this.errors = [];
   this.username = '';
   this.password = '';
   this.router.navigate(['/listarPsicologo'])
 }

 cadastrar(){
   const usuario: Usuario = new Usuario();
   usuario.email = this.username;
   usuario.senha = this.password;
   usuario.ativo = true;
   usuario.roles="USER";

   this.psicologoService.adicionarPsicologoLogin(this.username).subscribe( response => {
    this.psicologo = response;
    this.errors = [];
    usuario.idPessoa = response.idPessoa;

    this.authService.salvar(usuario).subscribe( response => {
     this.mensagemSucesso = "Cadastro efetuado com sucesso! Efetue o login."
     this.errors = [];
    }, errorResponse => {
      this.errors = errorResponse.error.errors;
      this.mensagemSucesso = null;
    })
   })
 }


}

