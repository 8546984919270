<h2>Cadastrar Psicólogo</h2>
<mat-card>
  <mat-horizontal-stepper [linear]="isLinear" #stepper>

    <mat-step *ngIf="Nconcordo==true && role=='ROLE_USER'">
      <ng-template matStepLabel>Termos de uso e política de privacidade</ng-template>
        <app-termo></app-termo>
        <!-- <div>
          <button mat-button matStepperNext>Próximo</button>
        </div> -->
    </mat-step>

    <mat-step >
      <ng-template matStepLabel>Dados Pessoais</ng-template>
        <app-dados-pessoais></app-dados-pessoais>
    </mat-step>

    <mat-step >
      <ng-template matStepLabel>Endereço</ng-template>
      <app-endereco></app-endereco>
    </mat-step>

    <mat-step *ngIf="showStep">
      <ng-template matStepLabel>Consultórios</ng-template>
        <app-consultorios ></app-consultorios>
    </mat-step>

    <mat-step *ngIf="showStep">
      <ng-template matStepLabel>Horários</ng-template>
      <app-horarios ></app-horarios>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>Fim</ng-template>
      <p>Cadastro Finalizado.</p>
      <!-- <div style="display: flex; justify-content: space-evenly;">
        <button mat-button matStepperPrevious>Voltar</button>
      </div> -->
    </mat-step>

  </mat-horizontal-stepper>
</mat-card>
