import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PsicologoService } from '../psicologo/psicologo.service';

@Component({
  selector: 'app-termo',
  templateUrl: './termo.component.html',
  styleUrls: ['./termo.component.css']
})
export class TermoComponent implements OnInit {

  // public zeroFormGroup: FormGroup;

  constructor(public psicologoService: PsicologoService ) { }

  ngOnInit(): void {
  }

  concordoTermoCliente(){
      this.psicologoService.addConcordoTermoCliente();
  }

}
