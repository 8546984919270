
<div class="canvasLogin">
  <mat-card class="card">
    <div class="title">Cadastrar</div>
    <form (submit)="onSubmit();" #loginForm="ngForm">
      <mat-form-field class="trintaEtres">
        <input type="email" required matInput placeholder="Login" [(ngModel)]="username" name="username" class="username" autocomplete="off" [email]="true" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
        <mat-error>Digite um e-mail válido</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres">
        <input type="password" required matInput placeholder="senha" ngModel name="password" [(ngModel)]="password">
        <mat-error >Digite uma senha válida</mat-error>
      </mat-form-field>
      <mat-error *ngFor="let erro of errors" class="loginError">
        {{ erro }}
      </mat-error>

      <button mat-raised-button
        (click)="cadastrar()"
        [disabled]="loginForm.invalid">Cadastrar
      </button>

      <div *ngIf="mensagemSucesso" class="mensagemSucesso">
        {{ mensagemSucesso }}
      </div>

        <div class="link">
          <a (click)="cancelaCadastro()" *ngIf="!mensagemSucesso">Cancelar</a>
        </div>
        <div class="link">
          <a (click)="cancelaCadastro()" *ngIf="mensagemSucesso">Voltar</a>
        </div>

    </form>
  </mat-card>
</div>
