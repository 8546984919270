import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService {

  constructor(private httpClient: HttpClient){
  }

  public get(): Observable<any> {
    return this.httpClient.get("");
  }

  public post(body:any): Observable<any>{
    return this.httpClient.post(environment.url + '/login', body);
  }

  public getUserByToken(token: string): Observable<any>{
    let params = new HttpParams();
        params = params.append('token', token);
    return this.httpClient.get(environment.url + '/login', {params: params});
  }

}
