import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { SidenavServiceService } from '../service/sidenav-service.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {


  @ViewChild('sidenav') public sidenav: MatSidenav;

  usuarioLogado: string;
  roles: string;
  email;
  id:number;


  constructor(private sidenavService: SidenavServiceService, private authService: AuthService, private psicologoService: PsicologoService, private router: Router) {
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnInit(): void {
    this.usuarioLogado = this.authService.getUsuarioAutenticado();
    this.psicologoService.getPsicologoByEmail(this.usuarioLogado).subscribe(resultado =>{
      this.id = resultado.idPessoa;
      // console.log("id");
      // console.log(this.id);
    });
  }

  pegaRoles(){
    this.roles = this.authService.obterRoles();
    if(this.roles == "ROLE_ADMIN"){
      return true;
    }
    return false;
  }

  getPsicologo(): void{
  this.router.navigate(['/psicologo/' + this.id]);
  }

  listarTriagensTerapeuta(): void{
    this.router.navigate(['/questionario/triagem/' + this.id]);
  }

  listarTerapiasTerapeuta(): void {
    this.router.navigate(['terapia/terapeutas/' + this.id]);
  }


}
