export class Questionario {
  nome?: string;
  sobrenome?: string;
  fone?: string;
  email?: string;
  dataNasc?: any;
  terapeuta?: boolean;
  escolaridade?: string;

  cpf?: string;

  cep?: string;
  logradouro?: string;
  nResid?: string;
  complemento?: string;
  estado?: string;
  cidade?: string;
  bairro?: string;

  idQuestionario?: number;
  imagina?: string;
  fazTerapia?: boolean;
  tomaRemedio?: boolean;
  ocupacoes?: string;
  colaboraFamilia?: string;
  pessoasResidem?: string;
  pessoasResidemDescritivo?: string;
  temHobbie?: boolean;
  diversaoHobbieDescritivo?: string;
  estuda?: boolean;
  estudaCursosDescritivo?: string;
  sonhos?: string;
  perda?: boolean;
  perdaDescritivo?: string;
  investirAte?: string;
  prefereMulher?: string;
  impressaoTerapeuta?: string;
  bairroPreferencia?: string;

  trazTerapia?;
  relacao?;
  condicaoFinanceira?;
  relacaoDinheiro?;
  porqueTbi?;
  fatorTensao?;
  idPessoa?: number = 0;

  diaSemana?: number;
  agendado?: boolean;
  dataPreenchido?: string;
  dataTriagem?: string
  periodo?: string;
  status?: string;
  concluido?: boolean;
  visualizarPsicologo?: boolean;
  idAgendamentoTriagem?: number;

  idEndereco?: number;

  trazTerapiaDesc?: string;
  fazTerapiaDesc?: string;

  // de novos Bancos
  versao?: string;
  createdAt?: string;
}
