import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { Questionario } from '../questionario/questionario.model';
import { QuestionarioService } from '../questionario/questionario.service';

@Component({
  selector: 'app-questionario-client',
  templateUrl: './questionario-client.component.html',
  styleUrls: ['./questionario-client.component.css']
})
export class QuestionarioClientComponent implements OnInit {

  public questAlter;
  isLinear = false;
  // public zeroFormGroup: FormGroup;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public formJoin: FormGroup;
  questionario: Questionario = new Questionario();
  public pessoa: Psicologo;
  public pessoa1: Psicologo;
  public pessoa2: Psicologo;
  public formGroup: FormGroup;
  public consultorio: Consultorio;
  periodoSet;
  diaSet;

  diaSemana: Array<{id:number, option:string}> = [
    {id:1, option:"DOMINGO"},
    {id:2, option:"SEGUNDA"},
    {id:3, option:"TERÇA"},
    {id:4, option:"QUARTA"},
    {id:5, option:"QUINTA"},
    {id:6, option:"SEXTA"},
    {id:7, option:"SABADO"},
  ];

  periodo: Array<{id:number, option:string}> = [
    {id:1, option:"MANHA"},
    {id:2, option:"TARDE"},
    {id:3, option:"NOITE"},
  ];

  constructor(public questionarioService: QuestionarioService , private formBuilder: FormBuilder,
              public psicologoService: PsicologoService) { }

  ngOnInit(): void {
        // redirecionar para aws
    // window.location.href = 'http://tbi.terapiabreveintegrada.com.br/questionarioClient';
  }

  setDia(id: number): void{
    this.diaSet = id;
  }

  setPeriodo(id: number): void{
    this.periodoSet = id;
  }

  montaQuestionario(): void{
    this.questionario.diaSemana = 0;
    this.questionario.periodo = '';
    this.questionario.condicaoFinanceira = 5;
    this.questionario.fatorTensao = 8;
    this.questionario.porqueTbi = 5;
    this.questionario.relacaoDinheiro = 6;
    this.questionario.trazTerapia = 5;
    this.questionario.cidade = '';
    this.questionario.estado = '';
    this.questionario.escolaridade = '';
    this.questionario.bairroPreferencia = '';
    this.questionario.diaSemana = this.diaSet;
    this.questionario.periodo = this.periodoSet;
    this.psicologoService.adicionarQuestionario(this.questionario).subscribe();
  }

  montarPessoa(): void{
    this.psicologoService.adicionarDadosPessoais(this.firstFormGroup.value);
    this.psicologoService.adicionaEndereco(this.secondFormGroup.value);
    this.psicologoService.adicionarPsicologo();
    // console.log(this.firstFormGroup.value)
    // console.log(this.secondFormGroup.value);
    // this.pessoa1 = this.firstFormGroup.value;
    // this.pessoa2 = this.secondFormGroup.value;
    // this.pessoa =  Object.assign(this.pessoa1, this.pessoa2);
    // console.log(this.pessoa1.cpf);
    // console.log(this.pessoa.cpf);
    // this.onAdicionarPessoa();
  }

}
