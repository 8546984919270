import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { DiaSemana } from './dia-semana.model';

export class Horario {
  id?: number;
  pessoa: Psicologo = new Psicologo();
  consultorio: Consultorio = new Consultorio();
  diaSemana?: DiaSemana = new DiaSemana();
  horaInicio?: string;
  horaFim?: string;
  descricao?: string;
}
