<mat-sidenav-container fxFlexFill>
  <!-- <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" >

<mat-icon>menu</mat-icon>
</button> -->
<mat-sidenav #sidenav class="sidenav" mode="side" opened
fixedInViewport="false" fixedTopGap="64">
<mat-nav-list>
  <!-- <a class="close" (click)="sidenav.toggle()" mat-list-item>
    <mat-icon>close</mat-icon>
  </a> -->
  <!-- <a href="#" mat-list-item routerLink="/">Login</a> -->
  <!-- <a href="#" mat-list-item routerLink="/psicologoInserir">Novo Psicólogo</a> -->
  <!-- <a href="#" mat-list-item routerLink="/consultorio">Consultórios</a> -->
    <hr>
    <div class="user"><i class="material-icons">account_circle</i><span class="userName">{{ usuarioLogado }}</span></div>
    <hr>
    <ul *ngIf="pegaRoles()" style="margin-top: 20px;">
      <a href="#" routerLink="/listarQuestionario">
        <i class="material-icons icon_nav">content_paste</i>
        <h4>Questionários</h4>
      </a>
    </ul>

    <ul style="margin-top: 20px;">
      <a (click)="listarTerapiasTerapeuta()" routerLink="/listarTerapia">
        <i class="material-icons icon_nav">airline_seat_recline_extra</i>
        <h4>Terapia</h4>
      </a>
    </ul>

    <ul *ngIf="pegaRoles()">
      <a href="#" routerLink="/listarPsicologo">
        <i class="material-icons icon_nav">self_improvement</i>
        <h4>Terapeutas</h4>
      </a>
    </ul>

    <ul *ngIf="!pegaRoles()" style="margin-top: 20px;">
      <a (click)="getPsicologo()">
        <i class="material-icons icon_nav">wysiwyg</i>
        <h4>Cadastro</h4>
      </a>
    </ul>

    <ul>
      <a href="#" routerLink="/consultorio">
        <i class="material-icons icon_nav">roofing</i>
        <h4>Consultórios</h4>
      </a>
    </ul>

    <ul *ngIf="!pegaRoles()">
      <a href="#" routerLink="/horario">
        <i class="material-icons icon_nav">av_timer</i>
        <h4>Horários</h4>
      </a>
    </ul>

    <ul *ngIf="!pegaRoles()">
      <a (click)="listarTriagensTerapeuta()">
        <i class="material-icons icon_nav">checklist_rtl</i>
        <h4>Triagem</h4>
      </a>
    </ul>


</mat-nav-list>
</mat-sidenav>

<mat-sidenav-content>
<main>
  <router-outlet></router-outlet>
</main>

</mat-sidenav-content>
</mat-sidenav-container>
