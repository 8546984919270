// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url:'http://localhost:8080/api' ,
  // clientId: 'my-angular-app',
  // clientSecret: '@321',
  // tokenUrl: 'http://localhost:8080/oauth/token'



  // url:'https://tbi.terapiabreveintegrada.com.br:23380/api' ,
  // clientId: 'my-angular-app',
  // clientSecret: '@321',
  // tokenUrl: 'https://tbi.terapiabreveintegrada.com.br:23380/oauth/token'


  // url:'https://tbi-new.herokuapp.com/api' ,
  // clientId: 'my-angular-app',
  // clientSecret: '@321',
  // tokenUrl: 'https://tbi-new.herokuapp.com/oauth/token'

  // AWS
  url:'http://3.15.17.64:8081/api' ,
  clientId: 'my-angular-app',
  clientSecret: '@321',
  tokenUrl: 'http://3.15.17.64:8081/oauth/token'

  // url:'http://localhost:8080/api' ,
  // clientId: 'my-angular-app',
  // clientSecret: '@321',
  // tokenUrl: 'http://localhost:8080/oauth/token'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
