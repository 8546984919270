<form [formGroup]="secondFormGroup">

  <mat-form-field class="dez">
    <input type="text" minlength="5" matInput placeholder="CEP" name="cep" formControlName="cep" (focusout)="verificaCEP($event)">
    <mat-error *ngIf="secondFormGroup.controls['cep'].invalid">Digite um CEP válido</mat-error>
  </mat-form-field>

  <!-- appearance="fill" readonly-->
  <mat-form-field class="setenta" >
    <input type="text," minlength="3" matInput placeholder="logradouro" name="logradouro" formControlName="logradouro" >
    <mat-error *ngIf="secondFormGroup.controls['logradouro'].invalid">Logradouro com 3 ou mais letras</mat-error>
  </mat-form-field>

  <mat-form-field class="dez">
    <input type="text" pattern="[0-9]*" matInput placeholder="número" name="numeroR" formControlName="numeroR">
    <mat-error *ngIf="secondFormGroup.controls['numeroR'].invalid">Digite um número válido</mat-error>
  </mat-form-field>

  <mat-form-field class="trintaEtres">
    <input type="text" matInput placeholder="complemento" name="complemento" formControlName="complemento">
    <mat-error *ngIf="secondFormGroup.controls['complemento'].invalid">Digite um complemento válido</mat-error>
  </mat-form-field>

<mat-form-field class="trintaEtres">
  <input type="text" matInput placeholder="bairro" name="bairro" formControlName="bairro">
  <!-- <mat-error *ngIf="secondFormGroup.controls['bairro'].invalid">Digite um bairro válido</mat-error> -->
</mat-form-field>

  <mat-form-field class="trinta">
    <input type="text" matInput placeholder="estado" name="estado" formControlName="estado" >
    <mat-error *ngIf="secondFormGroup.controls['estado'].invalid">Digite um estado válido</mat-error>
  </mat-form-field>

  <mat-form-field class="setenta">
    <input type="text" matInput placeholder="cidade" name="cidade" formControlName="cidade"  >
    <mat-error *ngIf="secondFormGroup.controls['cidade'].invalid">Digite uma cidade válida</mat-error>
  </mat-form-field>

  <mat-form-field class="trinta" style="visibility: hidden; width: 0px!important;">
    <input type="text" matInput placeholder="idEndereco" name="idEndereco" formControlName="idEndereco" style="visibility: hidden; width: 0px!important;">
  </mat-form-field>

</form>


<div style="display: flex; justify-content: space-evenly;">
  <button mat-button matStepperPrevious>Voltar</button>
  <button (click)="onAdicionarEndereco()" [disabled]="secondFormGroup.invalid" mat-button matStepperNext>Próximo</button>
</div>
