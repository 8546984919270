import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PsicologoService } from '../psicologo/psicologo.service';

@Component({
  selector: 'app-psicologo-inserir-endereco',
  templateUrl: './psicologo-inserir-endereco.component.html',
  styleUrls: ['./psicologo-inserir-endereco.component.css']
})
export class PsicologoInserirEnderecoComponent implements OnInit {

  public formGroup: FormGroup;

  estado :Array<{id:number, option:string}> = [
    {id:1, option:"SP"},
    {id:2, option:"RJ"},
    {id:3, option:"MG"},
    {id:4, option:"DF"},
    {id:5, option:"SC"},
    {id:6, option:"BA"}
  ]

  cidade :Array<{id:number, option:string}> = [
    {id:1, option:"São Paulo"},
    {id:2, option:"Vargem Grande Paulista"},
    {id:3, option:"Mauá"},
    {id:4, option:"São Caetano"}
  ]

  constructor(public psicologoService: PsicologoService , private formBuilder:FormBuilder) {
    this.formGroup = this.formBuilder.group({
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      nResid: ['', Validators.required],
      complemento: ['', Validators.required],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.compose([Validators.required, Validators.maxLength(10)])]
    });
  }

  ngOnInit(): void {
  }

  onAdicionarPsicologoEndereco(){

  }

}
