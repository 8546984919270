import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { SidenavServiceService } from '../service/sidenav-service.service';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  constructor(private sidenav: SidenavServiceService, public router: Router, private authService: AuthService) { }

  toggleRightSidenav() {
    this.sidenav.toggle();
  }

  ngOnInit(): void {
  }

  logout(){
    this.authService.encerrarSessao();
    this.router.navigate(['/login'])
  }


}
