import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PsicologoService } from '../psicologo/psicologo.service';
import { Questionario } from '../questionario/questionario.model';
import { QuestionarioService } from '../questionario/questionario.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Triagem } from './triagem.model';


@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.css']
})
export class AgendamentoComponent implements OnInit {

  public id;
  public pessoa;
  public questionario: Questionario;
  public questionariosId: number;
  public dia;
  public diaTerapeuta = new Set();
  public diaTerapeutaString;
  public disponibilidade =[];
  public disponibilidadeFiltered = [];
  public diaSemanaTerapeuta: [];
  public diaMesTerapeuta: [];
  public agendamentoFormGroup: FormGroup;
  public dias;
  public anos: Array<any> = [];
  public mes: Array<any> = [];
  public diasSemana: Array<any> = [];
  public diasMes: Array<any> = [];
  public diaS: Array<any> = [];
  public horarios: Array<any> = [];
  public semDoisPontos: boolean = false;
  public minutos: Array<any> = [];
  public horas: Array<any> = [];
  public diasSemanasCerto: Array<any> = [];
  public triagem = new Triagem();
  public isAlterar: boolean = false;

  errorMsg: string;

  diaSemana: Array<{id:number, option:string}> = [
    {id:1, option:"DOMINGO"},
    {id:2, option:"SEGUNDA"},
    {id:3, option:"TERÇA"},
    {id:4, option:"QUARTA"},
    {id:5, option:"QUINTA"},
    {id:6, option:"SEXTA"},
    {id:7, option:"SABADO"},
  ];

  constructor(private route: ActivatedRoute, public psicologoService: PsicologoService, public questionarioService: QuestionarioService,
              private changeDetection: ChangeDetectorRef, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.isAlterar = this.questionarioService.isAlterar;
    this.agendamentoFormGroup = this.formBuilder.group({
      terapeuta: ['', Validators.required],
      diaSemana: ['', Validators.required],
      diaMes: ['', Validators.required],
      horarios: ['', Validators.required],
    });

    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.isAlterar = this.questionarioService.isAlterar;
      this.psicologoService.getDisponibilidade().subscribe(resultado => {
        this.disponibilidade = resultado;
        // console.log(this.disponibilidade);

        this.questionarioService.getTriagemById(id).subscribe(q => {
          this.triagem = q;
          // console.log(this.triagem);
          // console.log(this.triagem.agenda.diaSemana.idDiaSemana);
        this.onFilterTerapeuta(this.triagem.terapeuta.idPessoa);
        this.onFilterDiaMes(this.triagem.agenda.diaSemana.idDiaSemana);
        this.onFilterHorarios(this.triagem.diaMes);

        this.agendamentoFormGroup.patchValue({"terapeuta": this.triagem.terapeuta.idPessoa}); this.agendamentoFormGroup.get('terapeuta').updateValueAndValidity();
        this.agendamentoFormGroup.patchValue({"diaSemana": this.triagem.agenda.diaSemana.idDiaSemana}); this.agendamentoFormGroup.get('diaSemana').updateValueAndValidity();
        this.agendamentoFormGroup.patchValue({"diaMes": this.triagem.diaMes}); this.agendamentoFormGroup.get('diaMes').updateValueAndValidity();
        let hora:number = Number(this.triagem.agenda.horaInicio.substring(0,2));
        // console.log("hora");
        // console.log(hora);
        this.agendamentoFormGroup.patchValue({"horarios": hora}); this.agendamentoFormGroup.get('horarios').updateValueAndValidity();

      });

      });





    }

    this.findPaciente();
    this.findDisponibilidade();
  }

  findPaciente(): void{
    this.id = this.route.snapshot.paramMap.get('id');
    this.psicologoService.getPsicologoById(this.id).subscribe(resultado => {this.pessoa = resultado;});

    this.questionariosId = this.questionarioService.questionarioParaAgendamento;
    this.questionarioService.getQuestionarioById(this.questionariosId)
                                            .subscribe(resultado => {this.questionario = resultado,
                                              // console.log(this.questionario),
                                              this.dia = this.diaSemana.find( ({id}) => id === this.questionario.diaSemana);
                                              // console.log(this.dia.option);
                                            });

  }


  findDisponibilidade(): void{
    this.psicologoService.getDisponibilidade().subscribe(resultado => {this.disponibilidade = resultado});
  }






  public onFilterTerapeuta(id: number){
    this.mes=[];
    this.diaS=[];
    this.diasSemana=[];
    this.diaTerapeuta.clear();

    const index = this.disponibilidade.findIndex( (element) => element.id_terapeuta === id);

    this.anos = this.disponibilidade[index].anos;
    // console.log("anos");
    // console.log(this.anos);

    for(let o of this.anos){
          this.mes.push(o.mes)
    }
    // console.log("mes");
    // console.log(this.mes);

    console.log("diasSemana");
    for(let o of this.mes){
        this.diasSemana.push(o.diasSemana)
        this.diaTerapeuta.add(this.diaSemana.find( ({id}) => id === o.diasSemana.diaSemana));
        // console.log("diaTerapeuta");
        // console.log(this.diaTerapeuta);
   }
  }


  onFilterDiaMes(diaSemana){
    this.diasMes=[];

    const index = this.diasSemana.findIndex( (element) => element.diaSemana === diaSemana);
    this.diasMes.push(this.diasSemana[index]);

  //  console.log("diasMes");
  //  console.log(this.diasMes);
  }


  onFilterHorarios(diasMes: number){
    this.horarios=[];
    this.minutos=[];
    this.horas=[];
    this.diasSemanasCerto=[];
    this.semDoisPontos = false;

    // const index = this.diasSemana.findIndex( (element) => element.diasMes === diasMes);
    // this.diasSemanasCerto = this.diasSemana[index].horariosDTO;

  for (let dias of this.diasSemana){
    if (dias.diasMes === diasMes){
      this.diasSemanasCerto.push(dias.horariosDTO)
    }
  }

  for ( let x of this.diasSemanasCerto){
    for (let y of x){
      this.horarios.push(y);
    }
  }

  // for(let h of this.horarios){
  //   if(h.preenchido === true){
  //     h.minutos = h.minutos + "preenchido";
  //   }
  // }
    // console.log("diasSemanasCerto");
    // console.log(this.diasSemanasCerto);
    // console.log("horario");
    // console.log(this.horarios);
  }


  onAdicionarAgenda(){
    this.triagem.diaMes = this.agendamentoFormGroup.get('diaMes').value;

    for( let d of this.diasMes){
      if( d.diasMes == this.triagem.diaMes){
        this.triagem.mes = d.mes;
      }
    }

    this.triagem.idTerapeuta = this.agendamentoFormGroup.get('terapeuta').value;
    this.triagem.idPaciente = this.id;
    this.triagem.diaSemana = this.agendamentoFormGroup.get('diaSemana').value;

    const horaInicio = this.agendamentoFormGroup.get('horarios').value;
    let minutosInicio =  0;
    for(let horario of this.horarios){
      if (horaInicio == horario.horario){
        minutosInicio = horario.minutos;
      }
    }

    this.triagem.horaInicio =
    horaInicio.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    + ':' +
    minutosInicio.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });

    this.psicologoService.adicionarAgendamento(this.triagem).subscribe(reulstado => console.log(""));

    this.router.navigate(['/listarQuestionario'])
                    .then(() => {
    window.location.reload();
  });
  }

  onCancelarAlteracao(){
    this.router.navigate(['/listarQuestionario'])
                    .then(() => {
    window.location.reload();
  });
}

  // diaTerapeutaString(){
  //   this.diasSemana.forEach((element) =>{
  //       this.diaTerapeuta.push(this.diaSemana.find( ({id}) => id === element));
  //   })
  //   //  = this.diaSemana.find( ({id}) => id === this.diasSemana.diaSemana),

  // }



}

