import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Escolaridade } from '../psicologo/escolaridade.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { Questionario } from '../questionario/questionario.model';
import { QuestionarioService } from '../questionario/questionario.service';



@Component({
  selector: 'app-dados-pessoais',
  templateUrl: './dados-pessoais.component.html',
  styleUrls: ['./dados-pessoais.component.css']
})
export class DadosPessoaisComponent implements OnInit {


  @Input() questionario;
  @Input() psicologo;
  escolaridade: Array<Escolaridade> = [];
  public firstFormGroup: FormGroup;

  pessoaCpfExiste: Psicologo;
  errorMsg: string;

  cpf_cnpj;


  constructor( public psicologoService: PsicologoService , private formBuilder: FormBuilder,
    private route: ActivatedRoute, public questionarioService: QuestionarioService, private router: Router, public dialog: MatDialog ) {}



  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      idPessoa: ['', ],
      nome: ['', Validators.required],
      sobrenome: ['', Validators.required],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      cpf: this.formBuilder.control({ value: null, disabled: false}),
      dataNasc: ['', Validators.required],
      escolaridade: ['', Validators.required]
    });


    const id = this.route.snapshot.paramMap.get('id');

    if(id && this.router.url.includes('/psicologo')){
      this.psicologoService.getPsicologoById(Number(id)).subscribe(q => {this.psicologo = q;

        this.firstFormGroup.patchValue({"idPessoa": this.psicologo.idPessoa}); this.firstFormGroup.get('idPessoa').updateValueAndValidity();
        this.firstFormGroup.patchValue({"nome": this.psicologo.nome}); this.firstFormGroup.get('nome').updateValueAndValidity();
        this.firstFormGroup.patchValue({"sobrenome": this.psicologo.sobrenome}); this.firstFormGroup.get('sobrenome').updateValueAndValidity();
        this.firstFormGroup.patchValue({"cpf": this.psicologo.cpf}); this.firstFormGroup.get('cpf').updateValueAndValidity();
        this.firstFormGroup.patchValue({"telefone": this.psicologo.telefone}); this.firstFormGroup.get('telefone').updateValueAndValidity();
        this.firstFormGroup.patchValue({"email": this.psicologo.email}); this.firstFormGroup.get('email').updateValueAndValidity();
        this.firstFormGroup.patchValue({"escolaridade": this.psicologo.escolaridade.idEscolaridade}); this.firstFormGroup.get('escolaridade').updateValueAndValidity();
        this.firstFormGroup.patchValue({"dataNasc": this.psicologo.dataNasc}); this.firstFormGroup.get('dataNasc').updateValueAndValidity();
      });

    } else if(id)
    {
      this.questionarioService.getQuestionarioById(id).subscribe(q => {this.questionario = q;

        this.firstFormGroup.patchValue({"idPessoa": this.questionario.pessoa.idPessoa}); this.firstFormGroup.get('idPessoa').updateValueAndValidity();
        this.firstFormGroup.patchValue({"nome": this.questionario.pessoa.nome}); this.firstFormGroup.get('nome').updateValueAndValidity();
        this.firstFormGroup.patchValue({"sobrenome": this.questionario.pessoa.sobrenome}); this.firstFormGroup.get('sobrenome').updateValueAndValidity();
        this.firstFormGroup.patchValue({"cpf": this.questionario.pessoa.cpf}); this.firstFormGroup.get('cpf').updateValueAndValidity();
        this.firstFormGroup.patchValue({"telefone": this.questionario.pessoa.telefone}); this.firstFormGroup.get('telefone').updateValueAndValidity();
        this.firstFormGroup.patchValue({"email": this.questionario.pessoa.email}); this.firstFormGroup.get('email').updateValueAndValidity();
        this.firstFormGroup.patchValue({"escolaridade": this.questionario.pessoa.escolaridade.idEscolaridade}); this.firstFormGroup.get('escolaridade').updateValueAndValidity();
        this.firstFormGroup.patchValue({"dataNasc": this.questionario.pessoa.dataNasc}); this.firstFormGroup.get('dataNasc').updateValueAndValidity();
      });
    }


    this.updateEscolaridade();
  }


   onAdicionarPessoa(){
    this.updateEscolaridade();
    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.psicologoService.alterarDadosPessoais(this.firstFormGroup.value);
    }else {
      this.psicologoService.adicionarDadosPessoais(this.firstFormGroup.value);
    }
  }

  updateEscolaridade(){
    this.psicologoService.getEscolaridade().subscribe(resultado => this.escolaridade = resultado);
  }

  existeCpf(event: any){
    let value = event.target.value.replace('.', '');
    value = value.replace('.', '');
    value = value.replace('-', '');
      this.psicologoService.verificaCPF(value).subscribe(q => {
        const id = this.route.snapshot.paramMap.get('id');
        if (q.cpf != null && !id){
          this.dialog.open(DialogCpf, {
            height: '35%',
            width: '30%',
            data: {
              error: "Este CPF já está cadastrado !",
            },
          })
        }
      });
  }

  isCPF(): boolean{
    return this.cpf_cnpj == null ? true : this.cpf_cnpj.length < 12 ? true : false;
 }

 getCpfCnpjMask(): string{
    return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
 }

}



@Component({
  selector: 'dialog-cpf',
  templateUrl: 'dialog-cpf.html',
})
export class DialogCpf {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

export interface DialogData {
  error: string;
}
