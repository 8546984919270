import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TerapiaService } from './terapia.service';
import { Terapeuta } from '../agendamento/triagem.model';
import { Terapia } from './terapia.model';
import { Psicologo } from '../psicologo/psicologo.model';

@Component({
  selector: 'app-terapia',
  templateUrl: './terapia.component.html',
  styleUrls: ['./terapia.component.css']
})
export class TerapiaComponent implements OnInit {

  public terapiaFormGroup: FormGroup;
  public terapia:          Terapia = new Terapia;
  public id:               String;

  constructor(private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog,
                  private terapiaService: TerapiaService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.terapiaFormGroup = this.formBuilder.group({
      id_terapia:             ['', ],
      terapeuta:              ['', Validators.required],
      statusTerapiaList:      ['', Validators.required],
      dataInicioTerapia:      ['', Validators.required],
      dataFimTerapia:         ['', Validators.required],
      dataFimEstimado:        ['', Validators.required],
    });

    this.terapiaService.getTerapiaById(Number(this.id)).subscribe(result => {this.terapia = result;
      this.terapiaFormGroup.patchValue({"id_terapia":              this.terapia.idTerapia});               this.terapiaFormGroup.get('id_terapia').updateValueAndValidity();
      this.terapiaFormGroup.patchValue({"terapeuta":               result.terapeutas});                    this.terapiaFormGroup.get('terapeuta').updateValueAndValidity();
      this.terapiaFormGroup.patchValue({"statusTerapiaList":       this.terapia.statusTerapiaList});       this.terapiaFormGroup.get('statusTerapiaList').updateValueAndValidity();
      this.terapiaFormGroup.patchValue({"dataInicioTerapia":       new Date( this.terapia.dataInicioTerapia.toString().replace('-', '/'))});       this.terapiaFormGroup.get('dataInicioTerapia').updateValueAndValidity();
      this.terapiaFormGroup.patchValue({"dataFimTerapia":          new Date (this.terapia.dataFimTerapia.toString().replace('-', '/'))});          this.terapiaFormGroup.get('dataFimTerapia').updateValueAndValidity();
      this.terapiaFormGroup.patchValue({"dataFimEstimado":         new Date (this.terapia.dataFimEstimado.toString().replace('-', '/'))});         this.terapiaFormGroup.get('dataFimEstimado').updateValueAndValidity();
      this.terapiaFormGroup.get('terapeuta').setValue(this.terapia.terapeuta.idPessoa);
      this.terapiaFormGroup.get('statusTerapiaList').setValue(this.terapia.statusTerapia);

    });

    this.terapiaFormGroup.get('statusTerapiaList').setValue(this.terapia.statusTerapia);
  }

  compareStatusObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
}

alteraTerapia(): void{
  this.terapia = this.terapiaFormGroup.value;
  this.terapia.idTerapia = Number(this.id);
  this.terapiaService.alterarTerapia(this.terapia).subscribe(value => this.router.navigate(['listarTerapia']));

 }

}



