import { Endereco } from "../endereco/endereco.model";

export class Consultorio {
  idConsultorio: number;
  nomeConsultorio: string;
  cep?: string;
  logradouro?: string;
  numeroR?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  endereco?: Endereco;
  idPessoa?: number;
}
