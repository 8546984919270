import { Injectable } from '@angular/core';
import { Terapia } from './terapia.model';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TerapiaService {
  public idDel: number;

  constructor(private httpClient: HttpClient, private router: Router) { }

  getTerapiasTerapeuta(id: any): Observable<Terapia[]> {
    return this.httpClient.get<Terapia[]>(environment.url + '/terapia/terapeuta/' + id);
  }

  concluir(id: Number): Observable <Terapia> {
    return this.httpClient.get<Terapia>(environment.url + '/terapia/concluir/' + id);
  }

  retomar(id: Number) {
    return this.httpClient.get<Terapia>(environment.url + '/terapia/retomar/' + id);
  }

  getTerapias(request): Observable <Terapia[]>{
    let params = new HttpParams();
    params = params.append('page', request.page);
    params = params.append('size', request.size);
    return this.httpClient.get<Terapia[]>(environment.url + '/terapia', {params: params});
  }

  readById(id): Observable<any> {
    return this.httpClient.get<Terapia>(environment.url + '/terapia/' +  id).pipe(
      map((obj) => obj));
  }

  getTerapiaById(id: number): Observable<any> {
    return this.httpClient.get<Terapia>(environment.url + '/terapia/editar/' +  id);
  }

  deleteTerapia(id): Observable<Terapia[]>{
    return this.httpClient.delete<Terapia[]>(environment.url + '/terapia/' +  id);
  }

  alterarTerapia(terapia: Terapia): Observable<any> {
    return this.httpClient.put(environment.url + '/terapia/' +  terapia.idTerapia, terapia);
  }

  savarRelatorio(terapia: Terapia):Observable<any> {
    return this.httpClient.put(environment.url + '/terapia/relatorio/' +  terapia.idTerapia, terapia);
  }

  salvarSegmento1(terapia: Terapia) {
    return this.httpClient.put(environment.url + '/terapia/segmento1/' +  terapia.idTerapia, terapia);
  }

  salvarSegmento2(terapia: Terapia) {
    return this.httpClient.put(environment.url + '/terapia/segmento2/' +  terapia.idTerapia, terapia);
  }

  salvarSegmentoFinal(terapia: Terapia) {
    return this.httpClient.put(environment.url + '/terapia/segmentoFinal/' +  terapia.idTerapia, terapia);
  }
}
