
  <h2>Consultórios</h2>
    <div *ngIf="isPsicologoInserir">
      <button mat-button matStepperNext [disabled]="fifthFormGroup.invalid" (click)="onAdicionarConsultorio()">Próximo</button>
    </div>

  <form [formGroup]="fifthFormGroup">
    <mat-accordion>
      <div *ngFor="let consultorio of consultorios; let i = index">
        <!-- <mat-checkbox (change)="verify($event.checked, consultorio.idConsultorio)" class="example-margin" formControlName="{{'check-' + i}}"></mat-checkbox> -->
        <mat-expansion-panel style="display: inline-table;width: 100%;">

          <mat-expansion-panel-header>
            <i class="material-icons person">roofing</i>
            <div class="nomeQuestionarioRead">{{consultorio.nomeConsultorio}}</div>

<!--
             <div class="action">
                 <ng-container  matColumnDef="action">
                 <th mat-header-cell *matHeaderCellDef>Ações</th>
                 <td mat-cell>
                 <a (click)="openDialog(consultorio.idConsultorio)"   class="delete">
                     <i class="material-icons">
                         delete
                     </i>
                 </a>
                 </td>
               </ng-container>
              </div> -->

              <div class="navHor">
                <ul>
                  <li class="delete">
                    <a (click)="openDialog(consultorio.idConsultorio)"   class="delete">
                      <i class="material-icons">delete</i>
                      <b>Deletar</b>
                    </a>
                  </li>
                </ul>
              </div>


           </mat-expansion-panel-header>

          <span  *ngIf="!consultorio.privado || !ehAdmin" class="titulo">Logradouro:</span> <span *ngIf="!consultorio.privado || !ehAdmin" class="texto">{{consultorio.endereco.logradouro}}</span>
          <span  *ngIf="!consultorio.privado || !ehAdmin" class="titulo">Número:</span> <span  *ngIf="!consultorio.privado || !ehAdmin"class="texto">{{consultorio.endereco.numeroR}}</span><br>
          <span  *ngIf="!consultorio.privado || !ehAdmin" class="titulo">Bairro:</span> <span  *ngIf="!consultorio.privado || !ehAdmin" class="texto">{{consultorio.endereco.bairro}}</span>
          <span  *ngIf="!consultorio.privado || !ehAdmin" class="titulo">Cidade:</span> <span  *ngIf="!consultorio.privado || !ehAdmin" class="texto">{{consultorio.endereco.cidade}}</span><br>
          <span  *ngIf="consultorio.privado" class="titulo">Endereço privado</span>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </form>

  <mat-card *ngIf="!isHidden" style="margin-top: 20px;">
    <h2>Novo Consultório</h2>
    <form [formGroup]="secondFormGroup" >

      <mat-form-field class="cem">
        <input type="text" minlength="5" matInput placeholder="nome Consultorio" name="nomeConsultorio" formControlName="nomeConsultorio" (focusout)="salvaNome($event)">
        <mat-error *ngIf="secondFormGroup.controls['nomeConsultorio'].invalid">Digite um nome válido</mat-error>
      </mat-form-field>
      <mat-form-field class="dez" appearance="outline">
        <input type="text" minlength="5" matInput placeholder="CEP" name="cep" formControlName="cep" (focusout)="verificaCEP($event)" >
        <mat-error *ngIf="secondFormGroup.controls['cep'].invalid">Digite um CEP válido</mat-error>
      </mat-form-field>

      <mat-form-field class="setenta" >
        <input type="text," minlength="3" matInput placeholder="logradouro" name="logradouro" formControlName="logradouro" >
        <mat-error *ngIf="secondFormGroup.controls['logradouro'].invalid">Logradouro com 3 ou mais letras</mat-error>
      </mat-form-field>

      <mat-form-field class="dez">
        <input type="text" pattern="[0-9]*" matInput placeholder="número" name="numeroR" formControlName="numeroR">
        <mat-error *ngIf="secondFormGroup.controls['numeroR'].invalid">Digite um número válido</mat-error>
      </mat-form-field>

      <mat-form-field class="trintaEtres">
        <input type="text" matInput placeholder="complemento" name="complemento" formControlName="complemento">
        <mat-error *ngIf="secondFormGroup.controls['complemento'].invalid">Digite um complemento válido</mat-error>
      </mat-form-field>

    <mat-form-field class="trintaEtres" >
      <input type="text" matInput placeholder="bairro" name="bairro" formControlName="bairro" >
      <mat-error *ngIf="secondFormGroup.controls['bairro'].invalid">Digite um bairro válido</mat-error>
    </mat-form-field>

    <mat-form-field class="trinta" >
      <input type="text" matInput placeholder="estado" name="estado" formControlName="estado" >
      <mat-error *ngIf="secondFormGroup.controls['estado'].invalid">Digite um estado válido</mat-error>
    </mat-form-field>

    <mat-form-field class="setenta" >
      <input type="text" matInput placeholder="cidade" name="cidade" formControlName="cidade"  >
      <mat-error *ngIf="secondFormGroup.controls['cidade'].invalid">Digite uma cidade válida</mat-error>
    </mat-form-field>

    <mat-checkbox style="margin-left: 30px;" matInput placeholder="privado" name="privado" formControlName="privado">Endereço privado</mat-checkbox>

    </form>

    <div>
      <button (click)="addOneConsultorio()" [disabled]="secondFormGroup.invalid" mat-button>Inserir</button>
    </div>

</mat-card>

<mat-card style="margin-top: 32px;" *ngIf="!ehAdmin">
  <button (click)="mostrarForm()" mat-button class="novo">Novo Consultório</button>
</mat-card>



