import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Horario } from 'src/app/horarios/horario.model';
import { Consultorio } from 'src/app/psicologo/consultorio.model';
import { Psicologo } from 'src/app/psicologo/psicologo.model';
import { PsicologoService } from 'src/app/psicologo/psicologo.service';
import { QuestionarioService } from 'src/app/questionario/questionario.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-psicologo-read',
  templateUrl: './psicologo-read.component.html',
  styleUrls: ['./psicologo-read.component.css']
})
export class PsicologoReadComponent implements OnInit {

  public psicologos: Array<Psicologo> = [];
  public pessoas: Array<Psicologo>;
  public seventhFormGroup: FormGroup;
  public idDel: number;
  psicologo: Psicologo;


  sortedData: Psicologo[];
  ELEMENT_DATA = new Array<Psicologo>();
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Horario>;
  consultorio: Consultorio = new Consultorio();

  totalElements: number = 0;

  constructor(public psicologoService: PsicologoService, public dialog: MatDialog, public router: Router, private formBuilder: FormBuilder) {
    this.seventhFormGroup = this.formBuilder.group({});
    // this.sortedData = this.ELEMENT_DATA;
  }

  ngOnInit(): void {
    this.onListarPsicologo({ page: "0", size: "5" });
    // this.updateHorario();
    // this.dataSource.data = this.ELEMENT_DATA;
  }

  public onListarPsicologo(request): void{
    this.psicologoService.getPsicologos(request).subscribe(resultado =>
      {
      this.psicologos = resultado['content'],
      this.totalElements = resultado['totalElements']
      }
      // resultado['content'].forEach(pessoa => {
      //   if(pessoa.terapeuta == true){
      //     this.psicologos.push(pessoa);
      //   }
      //   this.totalElements = this.psicologos.length
      // }));
    // console.log("psicologos")
    // console.log(this.psicologos);
    )
  }

  nextPage(event: PageEvent) {
    const request = {};
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.onListarPsicologo(request);
  }

  getPsicologo(id): void{
    this.router.navigate(['/psicologo/' + id]);
  }

  openDialog(id: number): void {
    this.psicologoService.idDel = id;
    const dialogRef = this.dialog.open(DialogPsicologoDeleteComponent, {
      height: '250px',
      width: '400px',});
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      console.log(" ");
    });
  }

  novoTerapeuta(){
    this.router.navigate(['/loginCadastrar'])
  }

  // updateHorario(){
    // this.psicologoService.getHorarios().subscribe(resultado => this.ELEMENT_DATA = resultado);
    // this.ELEMENT_DATA = this.psicologos;
  // }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }

  // sortData(sort: Sort) {
  //   const data = this.ELEMENT_DATA.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.sortedData = data;
  //     return;
  //   }

    // this.sortedData = data.sort((a, b) => {
    //   const isAsc = sort.direction === 'asc';
    //   switch (sort.active) {
    //     case 'diaSemana': return this.compare(a.horarios.iaSemana, b.diaSemana.idDiaSemana, isAsc);
    //     case 'horaDe': return this.compare(a.horaInicio, b.horaInicio, isAsc);
    //     case 'horaAte': return this.compare(a.horaFim, b.horaFim, isAsc);
    //     default: return 0;
    //   }
    // });
  }

    // compare(a: number | string, b: number | string, isAsc: boolean) {
    //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    // }




@Component({
  selector: 'dialog-psicologo-delete-component',
  templateUrl: 'dialog-psicologo-delete-component.html',
})
export class DialogPsicologoDeleteComponent implements OnInit{


  psicologo: Psicologo;
  id: number = this.psicologoService.idDel;

  constructor(private psicologoService: PsicologoService) { }

  ngOnInit(): void {
    this.psicologoService.readById(this.id).subscribe((psicologo) => {
      this.psicologo = psicologo;
    });
  }



  delete(): void  {
    this.psicologoService.deletePsicologo(this.psicologo.idPessoa).subscribe(
      () => this.psicologoService.getPsicologos({ page: "0", size: "5" }));
    window.location.reload();

  }
}
