import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Terapia } from '../terapia/terapia.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TerapiaService } from '../terapia/terapia.service';

@Component({
  selector: 'app-segmento1',
  templateUrl: './segmento1.component.html',
  styleUrls: ['./segmento1.component.css']
})
export class Segmento1Component implements OnInit {

  public segmento1FormGroup: FormGroup;
  public terapia:          Terapia = new Terapia;
  public id:               String;

  constructor(private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog,
    private terapiaService: TerapiaService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.segmento1FormGroup = this.formBuilder.group({
      id_terapia:             ['', ],
      seg1Avaliacao:              ['', Validators.required],
    });

    this.terapiaService.getTerapiaById(Number(this.id)).subscribe(result => {this.terapia = result;
      this.segmento1FormGroup.patchValue({"id_terapia":              this.terapia.idTerapia});               this.segmento1FormGroup.get('id_terapia').updateValueAndValidity();
      this.segmento1FormGroup.patchValue({"seg1Avaliacao":               this.terapia.seg1Avaliacao});           this.segmento1FormGroup.get('seg1Avaliacao').updateValueAndValidity();
    });
  }

  alteraSegmento1() {
    this.terapia = this.segmento1FormGroup.value;
    this.terapia.idTerapia = Number(this.id);
    this.terapiaService.salvarSegmento1(this.terapia).subscribe(value => this.router.navigate(['listarTerapia']));
  }

}
