import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginService } from '../login/login.service';
import { Usuario } from '../login/usuario';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';

@Component({
  selector: 'app-login-recuperar',
  templateUrl: './login-recuperar.component.html',
  styleUrls: ['./login-recuperar.component.css']
})
export class LoginRecuperarComponent implements OnInit {

  username: string;
  password: string;
  errors: String[];
  cadastrando: boolean;
  mensagemSucesso: string;
  psicologo: Psicologo;
  usuario: Usuario;

  constructor(public loginService: LoginService,
    private router: Router,
    private authService: AuthService,
    private psicologoService: PsicologoService,
    public dialog: MatDialog){
  }

  ngOnInit(): void {
        // redirecionar para aws
        // window.location.href = 'http://tbi.terapiabreveintegrada.com.br/recuperar';
  }

/*   onLogin(form: NgForm){
    if (form.invalid)
        return;
    // método login
    this.loginService.post(form.value).subscribe(() => {
      this.router.navigate(['/psicologoInserir']);
    });
    form.resetForm();
 } */

 onSubmit(){
  this.authService.recuperar(this.username)
                  .subscribe( response => {
                      this.loginService.getUserByToken(this.username).subscribe(response => {
                      this.usuario = response;
                      this.psicologoService.pessoa.idPessoa = this.usuario.idPessoa;
                      this.dialog.open(EmailEnviado, {
                        height: '35%',
                        width: '30%',
                      })
                    });
                  }, errorResponse => {
                    this.errors = ['Usuário não encontrado']
                  })
 }

 preparaCadastrar(event){
   event.preventDefault();
   this.cadastrando = true;
 }


 cancelaCadastro(){
   this.cadastrando = false;
   this.mensagemSucesso = null;
   this.errors = [];
   this.username = '';
   this.password = '';
 }

 cadastrar(){
   const usuario: Usuario = new Usuario();
   usuario.email = this.username;
   usuario.senha = this.password;
   usuario.ativo = true;
   usuario.roles="USER";

   this.psicologoService.adicionarPsicologoLogin(this.username).subscribe( response => {
    this.psicologo = response;

    usuario.idPessoa = response.idPessoa;

    this.authService.salvar(usuario).subscribe( response => {
     this.mensagemSucesso = "Cadastro efetuado com sucesso! Efetue o login."
     this.errors = null;
    }, errorResponse => {
      this.errors = errorResponse.error.errors;
      this.mensagemSucesso = null;
    })
   })
 }

voltar(){
  this.router.navigate(['/login'])
}

}

@Component({
  selector: 'email-enviado',
  templateUrl: 'email-enviado.html',
})
export class EmailEnviado {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private router: Router,) {}

  fechar(){
  this.router.navigate(['/login']).then(() => {
    window.location.reload()});
  }
}

export interface DialogData {
  error: string;
}
