import { Encaminhamento } from "../encaminhamento/encaminhamento.model";
import { Psicologo } from "../psicologo/psicologo.model"

export class Terapia {
  // id_terapia: number
  terapeuta : Psicologo = new Psicologo();
  paciente: Psicologo = new Psicologo();
  encaminhamento: Encaminhamento = new Encaminhamento();
  statusTerapia: string;
  dataInicioTerapia: Date;
  dataFimTerapia: Date;
  isEmTerapia: Boolean;
  seg1Avaliacao: String;
  isAvaliadoSeg1: Boolean;
  seg2Avaliacao: String;
  isAvaliadoSeg2: Boolean;
  relatorioAtendimento: String;
  isRelatorio: Boolean;
  dataRelatorio: Date;
  seg1DataAvaliacao: Date;
  seg2DataAvaliacao: Date;
  dataEncaminhamento: Date = new Date();
  dataFimEstimado?: Date;


  terapeutas?: Array<Psicologo> = new Array<Psicologo>();
  idTerapia: Number;
  statusTerapiaList?: Array<StatusTerapia> = new Array<StatusTerapia>();

  segFinalAvaliacao: String;

}


export class StatusTerapia {
  label?: String;
  id?: String;
}
