<!-- <form  [formGroup]="zeroFormGroup"> -->

     <h3> POLÍTICA DE PRIVACIDADE</h3>

<div style="width: 60%; height: 260px; border:1px solid darkgray; display: flex; flex-direction: column;">
  <pre style="flex-grow: 1; height: 260px;margin:2%;overflow-x:auto;">
Este aplicativo é mantido e operado por Terapia Breve Integrada.
Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso
aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos
às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).

Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política
de privacidade, que contém informações importantes sobre:
- Quem deve utilizar nosso aplicativo
- Quais dados coletamos e o que fazemos com eles;
- Seus direitos em relação aos seus dados pessoais; e
- Como entrar em contato conosco.

1. Dados que coletamos e motivos da coleta

Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o
disposto nesta seção.
1. Dados pessoais fornecidos expressamente pelo usuário

Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao
utilizar nosso aplicativo:
Nome, Cpf, Data nascimento, Contatos...
A coleta destes dados ocorre nos seguintes momentos:
quando o usuário preenche um formulário
Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
para retorno de contato e análise pra encaminhamento para terapia.
2. Dados sensíveis
O aplicativo poderá coletar os seguintes dados sensíveis dos usuários:
- dados relativos à saúde do usuário
A coleta de dados sensíveis ocorre nos seguintes momentos:
Os dados sobre o estado psicológico são coletados mediante entrevista com terapeuta.
Estes dados são coletados com as seguintes finalidades:
dados sobre a saúde psicológica são coletados para triagem terapêutica e encaminhamento para
tratamento.
A coleta e a utilização de dados pessoais sensíveis somente será feita com o consentimento
específico e destacado de seus titulares, exceto, se for o caso, nas hipóteses em que a Lei
Geral de Proteção de Dados permite o tratamento deste tipo de dados com fundamento em outras
bases legais distintas do consentimento.
Em qualquer caso, o tratamento de dados pessoais sensíveis somente ocorrerá para atender a
finalidades específicas expressas nesta política ou devidamente informadas ao usuário por
outros meios.
3. Dados de crianças e adolescentes

Nós coletamos os seguintes dados de crianças e adolescentes:
nome, cpf, contato, data nascimento, endereço.
A coleta de dados de crianças e adolescentes acontece nos seguintes momentos:
No momento de preenchimento de dados no formulário de triagem.
Os dados de crianças e de adolescentes que coletamos são utilizados exclusivamente com as
seguintes finalidades:
Para retorno dobre o processo de triagem.
O tratamento de dados de crianças e adolescentes é realizado com base no melhor interesse da
criança ou do adolescente.
4. Coleta de dados não previstos expressamente
Eventualmente, outros tipos de dados não previstos expressamente nesta Política de
Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário,
ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.
Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão
informadas aos usuários do aplicativo.

2. Compartilhamento de dados pessoais com terceiros
Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o
façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir
alguma ordem expedida por autoridade pública.

3. Por quanto tempo seus dados pessoais serão armazenados
Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de
tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento
e que considere os direitos de seus titulares, os direitos do controlador do aplicativo e
as disposições legais ou regulatórias aplicáveis.
Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de
nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a
necessidade de armazenamento em virtude de disposição legal ou regulatória.

4. Bases legais para o tratamento de dados pessoais

Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja,
uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral
de Proteção de Dados Pessoais.
Todas as Nossas atividades de tratamento de dados pessoais possuem uma base legal que as
fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais
que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas
a partir de nossos canais de contato, informados ao final desta Política.

5. Direitos do usuário
O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de
Dados Pessoais:
- confirmação da existência de tratamento;
- acesso aos dados;
- correção de dados incompletos, inexatos ou desatualizados;
- anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em
desconformidade com o disposto na lei;
- portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
comercial e industrial;
- eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos
previstos em lei;
- informação das entidades públicas e privadas com as quais o controlador realizou uso
compartilhado de dados;
- informação sobre a possibilidade de não fornecer consentimento e sobre as consequências
da negativa;
- revogação do consentimento.
É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados
tratados com fundamento em bases legais distintas do consentimento, a menos que os dados
seja desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.
1. Como o titular pode exercer seus direitos
Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos
dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que
possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os
direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário,
e o requerente receberá todas as informações relacionadas.

6. Medidas de segurança no tratamento de dados pessoais

Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos
não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.
As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a
finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos
e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas
semelhantes à nossa.
Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
Armazenamento de senhas criptografadas e restrições de acesso ao banco de dados.
Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é
possível que ocorra algum problema motivado exclusivamente por um terceiro -
como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva
do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro.
Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos,
nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas,
sobre as quais não temos nenhum tipo de controle.
De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar
risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a
Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o
disposto na Lei Geral de Proteção de Dados.

7. Reclamação a uma autoridade de controle
Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de
dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação à
Autoridade Nacional de Proteção de Dados.


8. Alterações nesta política

A presente versão desta Política de Privacidade foi atualizada pela última vez em: 13/12/2022.
Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente
para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela
disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas
já existentes.
Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.

9. Como entrar em contato conosco
Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados
pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais,
por algum dos canais mencionados abaixo:
E-mail: zoca.freire@gmail.com
Endereço postal: R. Purpurina, 490 - Vila Madalena, São Paulo - SP, 05435-030

    <button style="text-align: center;margin-left: auto;margin-right: auto;" (click)="concordoTermoCliente()" mat-button matStepperNext>Concordo</button>

  </pre>


<!-- </form> -->

<!-- <div> -->

<!-- </div> -->

</div>
