import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { AuthGuard } from './auth.guard';
import { ConsultoriosComponent } from './consultorios/consultorios.component';
import { HorariosComponent } from './horarios/horarios.component';
import { IndexComponent } from './index/index.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginCadastroComponent } from './login-cadastro/login-cadastro.component';
import { LoginRecuperarComponent } from './login-recuperar/login-recuperar.component';
import { LoginSenhaComponent } from './login-senha/login-senha.component';
import { LoginComponent } from './login/login.component';
import { PsicologoInserirEnderecoComponent } from './psicologo-inserir-endereco/psicologo-inserir-endereco.component';
import { PsicologoInserirComponent } from './psicologo-inserir/psicologo-inserir.component';
import { PsicologoReadComponent } from './psicologo-inserir/psicologo-read/psicologo-read.component';
import { QuestionarioClientComponent } from './questionario-client/questionario-client.component';
import { QuestionarioReadComponent } from './questionario/questionario-read/questionario-read.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { EncaminhamentoComponent } from './encaminhamento/encaminhamento.component';
import { TerapiaReadComponent } from './terapia/terapia-read/terapia-read.component';
import { TerapiaComponent } from './terapia/terapia.component';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { Segmento1Component } from './segmento1/segmento1.component';
import { Segmento2Component } from './segmento2/segmento2.component';
import { SegmentoFinalComponent } from './segmento-final/segmento-final.component';


const routes: Routes =[

  {path: '', component: LayoutComponent, canActivate: [AuthGuard], children: [
    {path: 'psicologoInserir', component: PsicologoInserirComponent},
    {path: 'psicologoInserirEndereco', component: PsicologoInserirEnderecoComponent},
    {path: 'questionario/:id', component: QuestionarioComponent},
    {path: 'listarQuestionario', component: QuestionarioReadComponent, canActivate: [AuthGuard]},
    {path: 'questionario/triagem/:id', component: QuestionarioReadComponent, canActivate: [AuthGuard]},
    {path: 'listarPsicologo', component: PsicologoReadComponent, canActivate: [AuthGuard]},
    {path: 'psicologo/:id', component: PsicologoInserirComponent},
    {path: 'consultorio', component:ConsultoriosComponent},
    {path: 'horario', component:HorariosComponent},
    {path: 'agendamento/:id', component: AgendamentoComponent},
    {path: 'loginCadastrar', component:LoginCadastroComponent},
    {path: 'questionario', component: QuestionarioComponent},
    {path: 'encaminhamento/:id', component: EncaminhamentoComponent},
    {path: 'listarTerapia', component: TerapiaReadComponent, canActivate: [AuthGuard]},
    {path: 'terapia/terapeutas/:id', component: TerapiaReadComponent, canActivate: [AuthGuard]},
    {path: 'terapia/:id', component: TerapiaComponent, canActivate: [AuthGuard]},
    {path: 'terapia/editar/:id', component: TerapiaComponent, canActivate: [AuthGuard]},
    {path: 'terapia/relatorio/:id', component: RelatorioComponent, canActivate: [AuthGuard]},
    {path: 'terapia/segmento1/:id', component: Segmento1Component, canActivate: [AuthGuard]},
    {path: 'terapia/segmento2/:id', component: Segmento2Component, canActivate: [AuthGuard]},
    {path: 'terapia/segmentoFinal/:id', component: SegmentoFinalComponent, canActivate: [AuthGuard]},
  ]},
  {path: 'index', component:IndexComponent},
  {path: 'login', component:LoginComponent},
  {path: 'recuperar', component:LoginRecuperarComponent},

  {path: 'loginSenha/:email', component:LoginSenhaComponent},
  {path: 'questionarioClient', component: QuestionarioClientComponent},
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
