<div class="canvas">
  <h2>QUESTIONÁRIO</h2>

    <mat-card>
    <mat-horizontal-stepper [linear]="isLinear" #stepper>



      <mat-step [stepControl]="firstFormGroup">
        <ng-template matStepLabel>Dados Pessoais</ng-template>
          <app-dados-pessoais></app-dados-pessoais>
          <!-- <div>
            <button mat-button matStepperNext>Próximo</button>
          </div> -->
      </mat-step>


      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>Endereço</ng-template>
        <app-endereco></app-endereco>
        <!-- <div>
          <button mat-button matStepperNext (click)="montarPessoa()" >Próximo</button>
        </div> -->
      </mat-step>





  <mat-step [stepControl]="thirdFormGroup">
    <ng-template matStepLabel>Questões</ng-template>
    <form (ngSubmit) = "montaQuestionario()" [formGroup]="thirdFormGroup">

      <mat-form-field class="cem">
        <mat-label>O que te traz à terapia e por que nesse momento?</mat-label>
        <mat-select formControlName="trazTerapia">
          <mat-option *ngFor="let traz of trazTerapia" [value]="traz.id" name="trazTerapia">
            {{traz.option}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field  appearance="fill" class="cem">
        <mat-label>Descritivo de o que te traz à terapia</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  name="trazTerapiaDesc"
                  formControlName="trazTerapiaDesc"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="55"></textarea>
      </mat-form-field>

      <mat-form-field class="cem">
        <input type="text," minlength="3" matInput placeholder="O que você imagina transformar e/ou construir na sua vida com esse processo? " name="imagina" formControlName="imagina">
      </mat-form-field>

      <div class="cem">
        <label id="fazTerapia">Você já fez terapia? </label><br>
        <mat-radio-group aria-labelledby="fazTerapia" name="fazTerapia" formControlName="fazTerapia">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field  appearance="fill" class="cem">
        <mat-label>Descritivo de por quanto tempo fez terapia</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  name="fazTerapiaDesc"
                  formControlName="fazTerapiaDesc"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="55"></textarea>
      </mat-form-field>


      <div class="cem">
        <label id="tomaRemedio">Toma ou tomou alguma medicação psiquiátrica?  </label><br>
        <mat-radio-group aria-labelledby="tomaRemedio" name="tomaRemedio" formControlName="tomaRemedio">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="cem">
        <input type="text," minlength="3" matInput placeholder="Quais são suas ocupações atualmente?" name="ocupacoes" formControlName="ocupacoes">
      </mat-form-field>

      <div class="cem">
        <label id="condicaoFinanceira">Como vc veria sua condição financeira nesse momento?</label><br>
        <mat-radio-group aria-labelledby="condicaoFinanceira" name="condicaoFinanceira" formControlName="condicaoFinanceira" >
          <div *ngFor="let cond of condicaoFinanceira"  [hidden]="cond.option == ''" class="divRadio">
            <mat-radio-button *ngIf="cond.option != ''" [value]="cond.id">{{cond.option}}</mat-radio-button><br>
          </div>
        </mat-radio-group>
      </div>

      <div class="cem">
        <label id="relacaoDinheiro">Como você veria sua relação com dinheiro? </label><br>
        <mat-radio-group aria-labelledby="relacaoDinheiro" name="relacaoDinheiro" formControlName="relacaoDinheiro">
          <div *ngFor="let rel of relacaoDinheiro"  [hidden]="rel.option == ''" class="divRadio">
            <mat-radio-button *ngIf="rel.option != ''" [value]="rel.id">{{rel.option}}</mat-radio-button><br>
          </div>
        </mat-radio-group>
      </div>

      <div class="cem">
        <label id="colaboraFamilia">Você colabora financeiramente com sua família? </label><br>
        <mat-radio-group aria-labelledby="colaboraFamilia" name="colaboraFamilia" formControlName="colaboraFamilia">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="trinta">
        <input type="text," matInput placeholder="Quantas pessoas residem com você? " name="pessoasResidem" formControlName="pessoasResidem">
      </mat-form-field>

      <mat-form-field class="setenta">
        <input type="text," minlength="3" matInput placeholder="Descreva as pessoas residem com você? " name="pessoasResidemDescritivo" formControlName="pessoasResidemDescritivo">
      </mat-form-field>

      <div class="cem">
        <label id="temHobbie">O que você faz para se divertir, tem algum hobbie?</label><br>
        <mat-radio-group aria-labelledby="temHobbie" name="temHobbie" formControlName="temHobbie">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="setenta">
        <input type="text," minlength="3" matInput placeholder="Descreva seus hobbies ou o que faz para se divertir" name="diversaoHobbieDescritivo" formControlName="diversaoHobbieDescritivo">
      </mat-form-field>

      <div class="trinta">
        <label id="estuda">Costuma estudar, fazer cursos?</label><br>
        <mat-radio-group aria-labelledby="estuda" name="estuda" formControlName="estuda">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="setenta">
        <input type="text," minlength="3" matInput placeholder="Descreva seus estudos e cursos" name="estudaCursosDescritivo" formControlName="estudaCursosDescritivo">
      </mat-form-field>

      <mat-form-field class="cem">
        <input type="text," minlength="3" matInput placeholder="Como estão seus sonhos para vida? " name="sonhos" formControlName="sonhos">
      </mat-form-field>

      <div class="cem">
        <label id="porqueTbi">Porque escolheu a TBI?</label><br>
        <mat-radio-group aria-labelledby="porqueTbi" name="porqueTbi" formControlName="porqueTbi">
          <div *ngFor="let pq of porqueTbi"  [hidden]="pq.option == ''" class="divRadio">
            <mat-radio-button  *ngIf="pq.option != ''" [value]="pq.id">{{pq.option}}</mat-radio-button><br>
          </div>
        </mat-radio-group>
      </div>

      <div class="cem">
        <section class="fatorTensao-section">
          <label style="margin-bottom: 15px;" id="fatorTensao">Existe algum fator de tensão em sua vida tal como:</label><br>
          <mat-radio-group aria-labelledby="fatorTensao" name="fatorTensao" formControlName="fatorTensao">
            <div *ngFor="let fator of fatorTensao"  [hidden]="fator.option == ''" class="divRadio">
              <mat-radio-button  *ngIf="fator.option != ''" [value]="fator.id">{{fator.option}}</mat-radio-button>
            </div>
          </mat-radio-group>
        </section>
      </div>

      <div class="cem">
        <label id="perda">Alguma perda significativa na sua vida?</label><br>
        <mat-radio-group aria-labelledby="perda" name="perda" formControlName="perda">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field class="setenta">
        <input type="text," minlength="3" matInput placeholder="Descreva esta perda" name="perdaDescritivo" formControlName="perdaDescritivo">
      </mat-form-field>

      <mat-form-field class="setenta">
        <input type="text,"  matInput placeholder="Quanto está disposto a investir na terapia? " name="investirAte" formControlName="investirAte">
      </mat-form-field>

      <!-- <div class="cem">
        <label id="prefereMulher">Tem preferência profissional mulher ?</label><br>
        <mat-radio-group aria-labelledby="prefereMulher" name="prefereMulher" formControlName="prefereMulher">
          <mat-radio-button value="true" >Sim</mat-radio-button>
          <mat-radio-button value="false" >Não</mat-radio-button>
        </mat-radio-group>
      </div> -->

      <mat-form-field class="setenta">
        <input type="text," minlength="3" matInput placeholder="Qual o bairro de Preferência para a terapia" name="bairroPreferencia" formControlName="bairroPreferencia">
      </mat-form-field>

      <!-- <mat-form-field class="setenta">
        <input type="text"  minlength="3" matInput placeholder="Impressão terapeuta" name="impressaoTerapeuta" formControlName="impressaoTerapeuta">
      </mat-form-field> -->

      <!-- <div class="cem">
        <section class="diaSemana-section">
          <label style="margin-bottom: 15px;" id="diaSemana">Qual o Dia da Semana melhor para uma entreviata de Triagem:</label><br>
          <mat-radio-group aria-labelledby="diaSemana" name="diaSemana" formControlName="diaSemana">
            <mat-radio-button *ngFor="let dia of diaSemana" [value]="dia.id" >{{dia.option}}</mat-radio-button>
          </mat-radio-group>
        </section>
      </div> -->

      <!-- <mat-form-field class="trintaEtres" >
        <mat-label>dia da semana preferido</mat-label>
        <mat-select formControlName="diaSemana">
          <mat-option *ngFor="let dia of diaSemana" [value]="dia.id" >
            {{dia.option}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="thirdFormGroup.controls['diaSemana'].invalid">Escolha um dia da semana</mat-error>
      </mat-form-field>

      <mat-form-field class="setenta">
        <input type="text,"  matInput placeholder="Dia da semana" name="diaSemana" formControlName="diaSemana">
      </mat-form-field>

      <mat-form-field class="trintaEtres" >
        <mat-label>período para uma entreviata</mat-label>
        <mat-select formControlName="periodo">
          <mat-option *ngFor="let per of periodo" [value]="per.id" >
            {{per.option}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="thirdFormGroup.controls['periodo'].invalid">Escolha um Período para uma entrevista de Triagem</mat-error>
      </mat-form-field> -->

      <!-- <div class="cem">
        <section class="periodo-section">
          <label style="margin-bottom: 15px;" id="periodo">Qual o melhor Período para uma entreviata de Triagem:</label><br>
          <mat-radio-group aria-labelledby="periodo" name="periodo" formControlName="periodo">
            <mat-radio-button *ngFor="let per of periodo" [value]="per.option" >{{per.option}}</mat-radio-button>
          </mat-radio-group>
        </section>
      </div> -->

  <mat-form-field class="trinta" style="visibility: hidden; width: 0px!important;">
    <input type="text" matInput placeholder="idAgendamento" name="idAgendamento" formControlName="idAgendamento" style="visibility: hidden; width: 0px!important;">
  </mat-form-field>


  <div style="display: flex; justify-content: space-evenly;">
    <button mat-button matStepperPrevious>Voltar</button>
    <button mat-button matStepperNext>Salvar</button>
  </div>

  </form>
  </mat-step>




  <mat-step>
    <ng-template matStepLabel>Fim</ng-template>
    <p>Cadastro Salvo!</p>
  </mat-step>

  </mat-horizontal-stepper>

  </mat-card>
</div>


