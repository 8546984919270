<h2>Terapias</h2>

<form [formGroup]="terapiaFormGroup">
  <mat-accordion>
    <div *ngFor="let terapia of terapiasFiltered; let i = index; trackBy: trackItem">

      <!-- <mat-checkbox (change)="verify($event.checked, questionarios.idQuestionario)" class="example-margin" formControlName="{{'check-' + i}}"></mat-checkbox> -->
      <mat-expansion-panel style="display: inline-table;width: 100%;">

        <mat-expansion-panel-header>

            <i class="material-icons person">person</i>
            <div class="nomeQuestionarioRead">{{terapia.paciente.nome}} {{terapia.paciente.sobrenome}}</div>

         <div class="agenda">
            <div class="infoTr">status: {{terapia.statusTerapia}} </div>
         </div>

         <div class="agenda">
          <div class="infoTr">terapeuta: {{terapia.terapeuta.nome}} {{terapia.terapeuta.sobrenome}} </div>
       </div>

         <!-- <div class="agendamento">
          <button class="botao" mat-button (click)="agendar()" *ngIf="!isAgendado">Agendar</button>
          <button class="botao" mat-button (click)="alterar()" *ngIf="isAgendado">Alerar</button>
          <button class="botao" mat-button (click)="cancelar()">Cancelar</button>
        </div>

        <div>
          <button class="botao" mat-button (click)="avaliar()">Avaliar</button>
        </div> -->

        <div class="navHor">
          <ul>
              <!-- <li class="home" *ngIf="!questionario.agendado && questionario.versao === 1">
                <a  class="agendar" (click)="vaiAgendar(questionario.pessoa.idPessoa, questionario.idQuestionario)">
                  <i class="material-icons">calendar_today</i>
                  <b>Agendar</b>
                </a>
              </li> -->
              <!-- <li class="contact" *ngIf="questionario.agendado">
                <a (click)="alterarTriagem(questionario.pessoa.idPessoa, questionario.idQuestionario)"  class="alterar">
                  <i class="material-icons">calendar_today</i>
                  <b>Alterar</b>
                </a>
              </li>
              <li class="contact" *ngIf="questionario.agendado">
                <a (click)="openDialogDeleteTriagem(questionario.idAgendamentoTriagem.idAgendamentoTriagem)"  class="cancelar">
                  <i class="material-icons">cancel_presentation</i>
                  <b>Cancelar</b>
                </a>
              </li> -->
              <li *ngIf="terapia.isEmTerapia && terapia.statusTerapia  != 'CONCLUIDO'" class="projects">
                <a (click)="concluir(terapia.idTerapia)" class="avaliar">
                  <i class="material-icons">check_circle_outline</i>
                  <b>Conc.</b>
                </a>
                <li *ngIf="terapia.statusTerapia  == 'CONCLUIDO' " class="contact">
                  <a (click)="retomar(terapia.idTerapia)" class="alterar">
                    <i class="material-icons">check_circle_outline</i>
                    <b>Ret.</b>
                  </a>
              </li>

              <li class="about" >
                <a  (click)="getRelatorio(terapia.idTerapia)"  class="segmento">
                  <i class="material-icons">edit_note</i>
                  <b>Relat.</b>
                </a>
              </li>
              <li class="about" >
                <a  (click)="getSegmento1(terapia.idTerapia)" class="segmento">
                  <i class="material-icons">article</i>
                  <b>Seg. 1</b>
                </a>
              </li>
              <li class="about" >
                <a  (click)="getSegmento2(terapia.idTerapia)"  class="segmento">
                  <i class="material-icons">article</i>
                  <b>Seg. 2 </b>
                </a>
              </li>

              <li class="about" >
                <a (click)="getTerapia(terapia.idTerapia)" class="edit">
                  <i class="material-icons">edit</i>
                  <b>Edit.</b>
                </a>
              </li>
              <li class="delete" >
                <a (click)="openDialogDeleteTerapia(terapia.idTerapia)" class="delete">
                  <i class="material-icons">delete</i>
                  <b>Delet.</b>
                </a>
              </li>
          </ul>
      </div>


    </mat-expansion-panel-header>

    <div>
      <h3>Dados Terapia</h3>
      <div class="retangulo">
        <table>
          <tr>
            <th style="display:none">Id</th>
            <th>Início </th>
            <th>Fim estimado</th>
            <th>Fim</th>
            <th>Triagem</th>
          </tr>
          <tr>
            <td style="display:none">{{terapia.idTerapia}}</td>
            <td>{{terapia.dataInicioTerapia}}</td>
            <td>{{terapia.dataFimEstimado}}</td>
            <td>{{terapia.dataFimTerapia}}</td>
            <td >{{terapia.encaminhamento.agendamentoTriagem.terapeuta.nome}} {{terapia.encaminhamento.agendamentoTriagem.terapeuta.sobrenome}}</td>
          </tr>
        </table>
      </div>
    </div>

      <div>
        <h3>Dados Pessoais</h3>
        <div class="retangulo">
          <table>
            <tr>
              <th>Cpf</th>
              <th>Telefone</th>
              <th>E-mail</th>
            </tr>
            <tr>
              <td>{{terapia.paciente.cpf}}</td>
              <td>{{terapia.paciente.telefone}}</td>
              <td>{{terapia.paciente.email}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>Escolaridade</th>
              <th>Nascimento</th>
              <th>Data Cadastro</th>
            </tr>
            <tr>
              <td>{{terapia.paciente.escolaridade.escolaridade}}</td>
              <td>{{terapia.paciente.dataNasc}}</td>
              <td>{{terapia.paciente.createdAt | slice:0:10}}</td>
            </tr>
          </table>
        </div>
      </div>


      <div>
        <h3>Dados Terapeuta</h3>
        <div class="retangulo">
          <table>
            <tr>
              <th style="display:none">Id</th>
              <th>Telefone</th>
              <th>E-mail</th>

            </tr>
            <tr>
              <td style="display:none">{{terapia.idTerapia}}</td>
              <td>{{terapia.terapeuta.telefone}}</td>
              <td>{{terapia.terapeuta.email}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>Escolaridade</th>
              <th>Nascimento</th>
              <th>Data Cadastro</th>
            </tr>
            <tr>
              <td>{{terapia.terapeuta.escolaridade.escolaridade}}</td>
              <td>{{terapia.terapeuta.dataNasc}}</td>
              <td>{{terapia.terapeuta.createdAt | slice:0:10}}</td>
            </tr>
          </table>
        </div>
      </div>



    </mat-expansion-panel>
  </div>
</mat-accordion>

</form>
<span *ngIf="ehAdmin">
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements" (page)="nextPage($event)"> </mat-paginator>
</span>
