<h2>Encaminhamento</h2>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="IdPaciente" style="display: none;">
    <th mat-header-cell *matHeaderCellDef style="display: none;"> IdPaciente </th>
    <td mat-cell *matCellDef="let element" style="display: none;"> {{element.idPaciente}} </td>
  </ng-container>

  <ng-container matColumnDef="Id">
    <th mat-header-cell *matHeaderCellDef style="display: none;"> Id </th>
    <td mat-cell *matCellDef="let element" style="display: none;"> {{element.id_questionario}} </td>
  </ng-container>

  <ng-container matColumnDef="Paciente">
    <th mat-header-cell *matHeaderCellDef style="padding-left: 35px;"> Paciente </th>
    <td mat-cell *matCellDef="let element" style="padding-left: 35px;"> {{element.nomePaciente}} </td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
  </ng-container>

  <ng-container matColumnDef="Triador">
    <th mat-header-cell *matHeaderCellDef> Triador </th>
    <td mat-cell *matCellDef="let element"> {{element.nomeTriador}} </td>
  </ng-container>

  <ng-container matColumnDef="Terapeuta">
    <th mat-header-cell *matHeaderCellDef> Terapeuta </th>
    <td mat-cell *matCellDef="let element"> {{element.nomeTerapeuta}} </td>
  </ng-container>

  <!-- <ng-container matColumnDef="Data_Triagem">
    <th mat-header-cell *matHeaderCellDef> Data Triagem </th>
    <td mat-cell *matCellDef="let element"> {{element.dataTriagem}} </td>
  </ng-container> -->

  <ng-container matColumnDef="Data_Enc">
    <th mat-header-cell *matHeaderCellDef> Data Encaminhamento </th>
    <td mat-cell *matCellDef="let element"> {{element.dataEncaminhamento}} </td>
  </ng-container>

  <ng-container matColumnDef="Data_Reav">
    <th mat-header-cell *matHeaderCellDef> Data Reavaliacao </th>
    <td mat-cell *matCellDef="let element"> {{element.dataReavaliacao}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<form (ngSubmit) = "onAdicionarTerapia()" [formGroup]="terapiaFormGroup" *ngIf="encaminhamento.nomeTerapeuta == null">

  <table  class="mat-elevation-z8" style="margin-top: 30px; padding-left: 25px; display: flex;">

    <mat-form-field class="trintaEtres" >
      <mat-label>Terapeutas</mat-label>
      <mat-select formControlName="terapeuta">
        <mat-option  *ngFor="let terapeuta of encaminhamento.terapeutaList" [value]="terapeuta.idPessoa" name="terapeuta">
          {{terapeuta.nome}} {{terapeuta.sobrenome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="trintaEtres datepicker">
      <!-- <mat-label>Data encaminhamento</mat-label> -->
      <input matInput [matDatepicker]="picker" formControlName="dataEnc" name="dataEnc" class="dataEnc">
      <mat-hint>Data encaminhamento</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="trintaEtres">
      <button mat-button [disabled]="terapiaFormGroup.invalid">Encaminhar</button>
    </div>

  </table>
</form>
