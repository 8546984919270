import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from './login/usuario';
import { environment } from '../environments/environment'
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl: string = environment.url + "/usuario"
  tokenUrl: string = environment.tokenUrl
  clientId: string = environment.clientId
  clientSecret: string = environment.clientSecret
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor( private httpClient: HttpClient) { }

  encerrarSessao(){
    localStorage.removeItem('access_token')
  }

  getUsuarioAutenticado(){
    const token = this.obterToken();
    if(token){
      const usuario = this.jwtHelper.decodeToken(token).user_name
      return usuario;
    }
    return null;
  }

  getCPFUsuarioAutenticado(){
    const token = this.obterToken();
    if(token){
      const usuario = this.jwtHelper.decodeToken(token).cpf
      return usuario;
    }
    return null;
  }

  obterRoles(){
    const token = this.obterToken();
    if(token){
      const usuario = this.jwtHelper.decodeToken(token).authorities
      return usuario;
    }
    return null;
  }

  obterToken(){
    const tokenString = localStorage.getItem("access_token")
    if(tokenString){
      const token = JSON.parse(tokenString).access_token
      return token;
    }
    return null;
  }

  isAuthenticated(): boolean {
    const token = this.obterToken();
    if(token){
      const expired = this.jwtHelper.isTokenExpired(token)
      return !expired;
    }
    return false;
  }

  salvar(usuario: Usuario):  Observable<any> {
    return this.httpClient.post<any>(this.apiUrl, usuario);
  }

  alterar(usuario: Usuario):  Observable<any> {
    return this.httpClient.put<any>(this.apiUrl+"/alterar", usuario);
  }

  logar(username: string, password:string): Observable<any>{
    const params = new HttpParams()
                                .set('username', username)
                                .set('password', password)
                                .set('grant_type', 'password')
    // const headers = {
    //   'Authorization': 'Basic ' + btoa(`${this.clientId}:${this.clientSecret}`),
    //   'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    //   'Access-Control-Allow-Origin': '*'
    // }

    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${this.clientId}:${this.clientSecret}`),
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      // 'Access-Control-Allow-Origin' : '*'
    });

    // let headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/x-www-urlencoded');
    // headers.set('Access-Control-Allow-Origin', '*');


    return this.httpClient.post(this.tokenUrl, params , { headers }
      // {'Access-Control-Allow-Origin':'*','Content-Type':'application/x-www-urlencoded'}
      );

    // return this.httpClient.post(this.tokenUrl, JSON.stringify({ username: username, password: password, grant_type: 'password'}), { headers: headers });

  }

  recuperar(usuario: String):  Observable<any> {
    return this.httpClient.get<any>(this.apiUrl +"/"+ usuario);
  }


}
