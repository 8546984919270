import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ConsultoriosComponent } from '../consultorios/consultorios.component';
import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';

import { PsicologoService } from '../psicologo/psicologo.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-psicologo-inserir',
  templateUrl: './psicologo-inserir.component.html',
  styleUrls: ['./psicologo-inserir.component.css']
})
export class PsicologoInserirComponent implements OnInit {

  public formGroup:FormGroup;
  isLinear = false;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourthFormGroup: FormGroup;
  showStep: boolean = true;
  showStepConsultorio: boolean = true;
  psicologo: Psicologo;
  public psyAlter;
  public Nconcordo: Boolean;
  // @Input() consultoriosParaHorario: Observable<any[]>;
  // consultorios: Array<any> = [];
  public role: String;

  escolaridade :Array<{id:number, option:string}> = [
    {id:1, option:"não frequentou"},
    {id:2, option:"fundamental"},
    {id:3, option:"ensino médio"},
    {id:4, option:"superior incompleto"},
    {id:5, option:"superior completo"},
    {id:6, option:"pós-graduação"}
  ]

  estado :Array<{id:number, option:string}> = [
    {id:1, option:"SP"},
    {id:2, option:"RJ"},
    {id:3, option:"MG"},
    {id:4, option:"DF"},
    {id:5, option:"SC"},
    {id:6, option:"BA"}
  ]

  cidade :Array<{id:number, option:string}> = [
    {id:1, option:"São Paulo"},
    {id:2, option:"Vargem Grande Paulista"},
    {id:3, option:"Mauá"},
    {id:4, option:"São Caetano"}
  ]

  semana :Array<{id:number, option:string}> = [
    {id:1, option:"domingo"},
    {id:2, option:"segunda"},
    {id:3, option:"terça"},
    {id:4, option:"quarta"},
    {id:5, option:"quinta"},
    {id:6, option:"sexta"},
    {id:7, option:"sábado"},
  ]

  // consultorio1 = new Consultorio(1, "Vila Mariana", "2165", "sei lá", "231", "teste", "Vila Mariana", "São Paulo", "SP");
  // consultorio2 = new Consultorio(2, "Paulista", "2165", "sei lá", "231", "teste", "Vila Mariana", "São Paulo", "SP");
  // consultorios: Array<Consultorio> =[
  //   this.consultorio1,
  //   this.consultorio2
  // ];



  constructor(public psicologoService: PsicologoService , private formBuilder:FormBuilder, private route: ActivatedRoute, private authService: AuthService) {
    // this.firstFormGroup = this.formBuilder.group({
    //   nome: ['testeInserir', Validators.required],
    //   sobrenome: ['teste', Validators.required],
    //   telefone: ['teste', Validators.required],
    //   email: ['teste', Validators.required],
    //   cpf: ['teste', Validators.compose([Validators.required, Validators.maxLength(10)])],
    //   dataNasc: ['', Validators.required],
    //   escolaridade: ['', Validators.required]
    // });
    this.secondFormGroup = this.formBuilder.group({
      cep: ['teste', Validators.required],
      logradouro: ['teste', Validators.required],
      numeroR: ['teste', Validators.required],
      complemento: ['teste', Validators.required],
      estado: ['teste', Validators.required],
      cidade: ['teste', Validators.required],
      bairro: ['teste', Validators.compose([Validators.required, Validators.maxLength(10)])]
    });
    this.thirdFormGroup = this.formBuilder.group({

    });
    this.fourthFormGroup = this.formBuilder.group({
      semana: ['', Validators.required],
      consultorio: ['', Validators.required],
      horaDe: ['', Validators.required],
      horaAte: ['', Validators.required],
    });
   }

  ngOnInit(): void {
    this.psicologoService.ehTerapeuta(true);
    this.showSteper();
    // this.consultoriosParaHorario.subscribe(resultado => this.consultorios = resultado);

    if (this.route.snapshot.paramMap.get('id')){
      this.montaAlteraQuestionario(Number(this.route.snapshot.paramMap.get('id')));
    }
  }

//   onAdicionarPsicologo(){
//     console.log("oi");
//     this.psicologoService.adicionarPsicologo(this.firstFormGroup.value).subscribe();
//  }

//  onAdicionarHorario(){
//   this.psicologoService.adicionarHorario(this.fourthFormGroup.value).subscribe();
// }

showSteper(){
  if(this.route.snapshot.paramMap.get('id')){
    this.showStep = false;
  }else{
    this.showStep = true;
  }
};

montaAlteraQuestionario(idNumber: number): void{
  if (idNumber){
    // console.log(idNumber);
    this.psicologoService.readById(idNumber).subscribe(q => this.psicologo = q);
    this.psicologoService.getPsicologoById(idNumber).subscribe(q => {
      this.psyAlter = q;
      this.Nconcordo = !this.psicologo.concordo;
      this.role = this.authService.obterRoles();
    // this.thirdFormGroup.patchValue({'trazTerapia': this.psyAlter.trazTerapia.idTrazTerapia});
    // this.thirdFormGroup.get('trazTerapia').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({'imagina': this.psyAlter.imagina});
    // this.thirdFormGroup.get('imagina').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({'fazTerapia': String(this.psyAlter.fazTerapia)});
    // this.thirdFormGroup.get('fazTerapia').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({'ocupacoes': this.psyAlter.ocupacoes});
    // this.thirdFormGroup.get('ocupacoes').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"tomaRemedio": String(this.psyAlter.tomaRemedio)});
    // this.thirdFormGroup.get('tomaRemedio').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"condicaoFinanceira": this.psyAlter.condicaoFinanceira.idCondicaoFinanceira});
    // this.thirdFormGroup.get('condicaoFinanceira').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"relacaoDinheiro": this.psyAlter.relacaoDinheiro.idRelacaoDinheiro});
    // this.thirdFormGroup.get('relacaoDinheiro').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"colaboraFamilia": String(this.psyAlter.colaboraFamilia)});
    // this.thirdFormGroup.get('colaboraFamilia').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"pessoasResidem": this.psyAlter.pessoasResidem});
    // this.thirdFormGroup.get('pessoasResidem').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"pessoasResidemDescritivo": this.psyAlter.pessoasResidemDescritivo});
    // this.thirdFormGroup.get('pessoasResidem').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"temHobbie": String(this.psyAlter.temHobbie)});
    // this.thirdFormGroup.get('temHobbie').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"diversaoHobbieDescritivo": this.psyAlter.diversaoHobbieDescritivo});
    // this.thirdFormGroup.get('diversaoHobbieDescritivo').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"estuda": String(this.psyAlter.estuda)});
    // this.thirdFormGroup.get('estuda').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"estudaCursosDescritivo": this.psyAlter.estudaCursosDescritivo});
    // this.thirdFormGroup.get('estudaCursosDescritivo').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"sonhos": this.psyAlter.sonhos}); this.thirdFormGroup.get('sonhos').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"porqueTbi": this.psyAlter.porqueTbi.idPorqueTbi}); this.thirdFormGroup.get('porqueTbi').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"fatorTensao": this.psyAlter.fatorTensao.idFatorTensao});
    // this.thirdFormGroup.get('fatorTensao').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"perda": String(this.psyAlter.perda)});
    // this.thirdFormGroup.get('perda').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"perdaDescritivo": this.psyAlter.perdaDescritivo}); this.thirdFormGroup.get('perdaDescritivo').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"investirAte": this.psyAlter.investirAte}); this.thirdFormGroup.get('investirAte').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"prefereMulher": String(this.psyAlter.prefereMulher)});
    // this.thirdFormGroup.get('prefereMulher').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"bairroPreferencia": this.psyAlter.bairroPreferencia}); this.thirdFormGroup.get('bairroPreferencia').updateValueAndValidity();
    // this.thirdFormGroup.patchValue({"impressaoTerapeuta": this.psyAlter.impressaoTerapeuta}); this.thirdFormGroup.get('impressaoTerapeuta').updateValueAndValidity();
});
    // console.log(this.questionario);
    // this.psicologoService.getPsicologoById(this.questionario.idPessoa).subscribe(pessoa => this.pessoa  = pessoa);
    // console.log(this.pessoa);
    // this.psicologoService.getConsultorioByPessoa(this.pessoa).subscribe(consultorio => this.consultorio  = consultorio);
    // console.log(this.consultorio);
  }
}

}
