export class Triagem {
  idAgendamentoTriagem?: number;
  diaMes: number;
  mes: number;
  cancelado: boolean = false;
  idTerapeuta: number;
  idPaciente: number;
  diaSemana: number;
  horaInicio: string;
  minutosInicio: string;

  terapeuta: Terapeuta;
  agenda: Agenda;

}


export class Terapeuta{
  idPessoa: number;
}


export class Agenda{
  diaSemana: DiaSemana;
  horaInicio: string;
}

export class DiaSemana{
  idDiaSemana: number;
  descricao: string;
}
