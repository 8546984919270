import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup , Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { DiaSemana } from '../horarios/dia-semana.model';
import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { Questionario } from './questionario.model';
import { QuestionarioService } from './questionario.service';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.css']
})
export class QuestionarioComponent implements OnInit {

  public questAlter;
  isLinear = false;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public formJoin: FormGroup;
  questionario: Questionario;
  public pessoa: Psicologo;
  public pessoa1: Psicologo;
  public pessoa2: Psicologo;
  public formGroup: FormGroup;
  public consultorio: Consultorio;



  escolaridade: Array<{id: number, option: string}> = [
    {id:0, option:""},
    {id: 1, option: 'não frequentou' },
    {id: 2, option: 'fundamental' },
    {id: 3, option: 'ensino médio' },
    {id: 4, option: 'superior incompleto' },
    {id: 5, option: 'superior completo' },
    {id: 6, option: 'pós-graduação' },
  ];

  estado :Array<{id:number, option:string}> = [
    {id:0, option:""},
    {id:1, option:"SP"},
    {id:2, option:"RJ"},
    {id:3, option:"MG"},
    {id:4, option:"DF"},
    {id:5, option:"SC"},
    {id:6, option:"BA"}
  ];

  cidade :Array<{id:number, option:string}> = [
    {id:0, option:""},
    {id:1, option:"São Paulo"},
    {id:2, option:"Vargem Grande Paulista"},
    {id:3, option:"Mauá"},
    {id:4, option:"São Caetano"}
  ];

  trazTerapia: Array<{id:number, option:string}> = [
    {id:0, option:""},
    {id:1, option:"Trabalho/ profissão"},
    {id:2, option:"Relação com cônjuge"},
    {id:3, option:"Relação com os filhos"},
    {id:4, option:"Relação com os pais ou família"},
  ];

  condicaoFinanceira: Array<{id:number, option:string}> = [
    {id:5, option:""},
    {id:1, option:"Com Dívidas"},
    {id:2, option:"Instável"},
    {id:3, option:"Estável"},
    {id:4, option:"Bem Sucedido"}
  ];

  relacaoDinheiro: Array<{id:number, option:string}> = [
    {id:0, option:""},
    {id:1, option:"Gasto mais do que possuo"},
    {id:2, option:"Sempre tenho uma reserva"},
    {id:3, option:"Não falta nem sobra"},
    {id:4, option:"Tenho medo de faltar"},
    {id:5, option:"Guardo sempre contando com imprevistos."},
  ];

  porqueTbi: Array<{id:number, option:string}> = [
    {id:0, option:""},
    {id:1, option:"por que é mais barato"},
    {id:2, option:"foi bem recomendada"},
    {id:3, option:"não sabia a quem recorrer"},
    {id:4, option:"se adequa a minha realidade"}
  ];

  fatorTensao: Array<{id:number, option:string}> = [
    {id:0, option: ""},
    {id:1, option:"dinheiro"},
    {id:2, option:"luto"},
    {id:3, option:"desabrigo"},
    {id:4, option:"desemprego"},
    {id:5, option:"depressão"},
    {id:6, option:"transtornos"},
    {id:7, option:"alcoolismo"},
  ];

  diaSemana: Array<{id:number, option:string}> = [
    {id:1, option:"DOMINGO"},
    {id:2, option:"SEGUNDA"},
    {id:3, option:"TERÇA"},
    {id:4, option:"QUARTA"},
    {id:5, option:"QUINTA"},
    {id:6, option:"SEXTA"},
    {id:7, option:"SABADO"},
  ];

  periodo: Array<{id:number, option:string}> = [
    {id:1, option:"MANHA"},
    {id:2, option:"TARDE"},
    {id:3, option:"NOITE"},
  ];

  impressaoTerapeuta: string;

  constructor( public questionarioService: QuestionarioService , private formBuilder: FormBuilder,
               public psicologoService: PsicologoService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.firstFormGroup = this.formBuilder.group({
    //   idPessoa: ['', ],
    //   nome: ['', Validators.required],
    //   sobrenome: ['', Validators.required],
    //   telefone: ['', Validators.required],
    //   email: ['', Validators.required],
    //   cpf: ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
    //   dataNasc: ['', Validators.required],
    //   escolaridade: ['', Validators.required]
    // });
    // this.secondFormGroup = this.formBuilder.group({
    //   cep: ['', Validators.required],
    //   logradouro: ['', Validators.required],
    //   nResid: ['', Validators.required],
    //   complemento: ['', Validators.required],
    //   estado: ['', Validators.required],
    //   cidade: ['', Validators.required],
    //   bairro: ['', Validators.compose([Validators.required, Validators.maxLength(10)])]
    // });
    this.thirdFormGroup = this.formBuilder.group({
      trazTerapia: ['', Validators.required],
      imagina: ['', Validators.required],
      fazTerapia: ['',Validators.required],
      tomaRemedio: ['', Validators.required],
      ocupacoes: ['', Validators.required],
      condicaoFinanceira: ['', Validators.required],
      relacaoDinheiro: ['', Validators.required],
      colaboraFamilia: ['', Validators.required],
      pessoasResidem: ['', Validators.required],
      pessoasResidemDescritivo: ['', Validators.required],
      temHobbie: ['', Validators.required],
      diversaoHobbieDescritivo: ['', Validators.required],
      estuda: ['', Validators.required],
      estudaCursosDescritivo: ['', Validators.required],
      sonhos: ['', Validators.required],
      porqueTbi: ['', Validators.required],
      fatorTensao: ['', Validators.required],
      perda: ['', Validators.required],
      perdaDescritivo: ['', Validators.required],
      investirAte: ['', Validators.required],
      // prefereMulher: ['', Validators.required],
      bairroPreferencia: ['', Validators.required],
      idAgendamentoTriagem: [''],
      trazTerapiaDesc: [''],
      fazTerapiaDesc: ['']
    });
    if (this.route.snapshot.paramMap.get('id')){
      this.montaAlteraQuestionario(Number(this.route.snapshot.paramMap.get('id')));
    }
  }


  montaAlteraQuestionario(idNumber: number): void{
    if (idNumber){
      // console.log(idNumber);
      this.questionarioService.readById(idNumber).subscribe(q => this.questionario = q);
      this.questionarioService.getQuestionarioById(idNumber).subscribe(q => {this.questAlter = q;
      this.thirdFormGroup.patchValue({'trazTerapia': this.questAlter.trazTerapia.idTrazTerapia});
      this.thirdFormGroup.get('trazTerapia').updateValueAndValidity();
      this.thirdFormGroup.patchValue({'imagina': this.questAlter.imagina});
      this.thirdFormGroup.get('imagina').updateValueAndValidity();
      this.thirdFormGroup.patchValue({'fazTerapia': String(this.questAlter.fazTerapia)});
      this.thirdFormGroup.get('fazTerapia').updateValueAndValidity();
      this.thirdFormGroup.patchValue({'ocupacoes': this.questAlter.ocupacoes});
      this.thirdFormGroup.get('ocupacoes').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"tomaRemedio": String(this.questAlter.tomaRemedio)});
      this.thirdFormGroup.get('tomaRemedio').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"condicaoFinanceira": this.questAlter.condicaoFinanceira.idCondicaoFinanceira});
      this.thirdFormGroup.get('condicaoFinanceira').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"relacaoDinheiro": this.questAlter.relacaoDinheiro.idRelacaoDinheiro});
      this.thirdFormGroup.get('relacaoDinheiro').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"colaboraFamilia": String(this.questAlter.colaboraFamilia)});
      this.thirdFormGroup.get('colaboraFamilia').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"pessoasResidem": this.questAlter.pessoasResidem});
      this.thirdFormGroup.get('pessoasResidem').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"pessoasResidemDescritivo": this.questAlter.pessoasResidemDescritivo});
      this.thirdFormGroup.get('pessoasResidem').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"temHobbie": String(this.questAlter.temHobbie)});
      this.thirdFormGroup.get('temHobbie').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"diversaoHobbieDescritivo": this.questAlter.diversaoHobbieDescritivo});
      this.thirdFormGroup.get('diversaoHobbieDescritivo').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"estuda": String(this.questAlter.estuda)});
      this.thirdFormGroup.get('estuda').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"estudaCursosDescritivo": this.questAlter.estudaCursosDescritivo});
      this.thirdFormGroup.get('estudaCursosDescritivo').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"sonhos": this.questAlter.sonhos}); this.thirdFormGroup.get('sonhos').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"porqueTbi": this.questAlter.porqueTbi.idPorqueTbi}); this.thirdFormGroup.get('porqueTbi').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"fatorTensao": this.questAlter.fatorTensao.idFatorTensao});
      this.thirdFormGroup.get('fatorTensao').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"perda": String(this.questAlter.perda)});
      this.thirdFormGroup.get('perda').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"perdaDescritivo": this.questAlter.perdaDescritivo}); this.thirdFormGroup.get('perdaDescritivo').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"investirAte": this.questAlter.investirAte}); this.thirdFormGroup.get('investirAte').updateValueAndValidity();
      // this.thirdFormGroup.patchValue({"prefereMulher": String(this.questAlter.prefereMulher)});
      // this.thirdFormGroup.get('prefereMulher').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"bairroPreferencia": this.questAlter.bairroPreferencia}); this.thirdFormGroup.get('bairroPreferencia').updateValueAndValidity();
      // this.thirdFormGroup.patchValue({"impressaoTerapeuta": this.questAlter.impressaoTerapeuta}); this.thirdFormGroup.get('impressaoTerapeuta').updateValueAndValidity();

      this.thirdFormGroup.patchValue({'idAgendamentoTriagem': this.questAlter.idAgendamentoTriagem}); this.thirdFormGroup.get('idAgendamentoTriagem').updateValueAndValidity();
      // this.thirdFormGroup.patchValue({"condicaoFinanceira": this.questAlter.periodo});
      this.thirdFormGroup.patchValue({"trazTerapiaDesc": this.questAlter.trazTerapiaDesc}); this.thirdFormGroup.get('trazTerapiaDesc').updateValueAndValidity();
      this.thirdFormGroup.patchValue({"fazTerapiaDesc": this.questAlter.fazTerapiaDesc}); this.thirdFormGroup.get('fazTerapiaDesc').updateValueAndValidity();
});
      // console.log(this.questionario);
      // this.psicologoService.getPsicologoById(this.questionario.idPessoa).subscribe(pessoa => this.pessoa  = pessoa);
      // console.log(this.pessoa);
      // this.psicologoService.getConsultorioByPessoa(this.pessoa).subscribe(consultorio => this.consultorio  = consultorio);
      // console.log(this.consultorio);
    }
  }



  montarPessoa(): void{
    this.psicologoService.adicionarDadosPessoais(this.firstFormGroup.value);
    this.psicologoService.adicionaEndereco(this.secondFormGroup.value);
    this.psicologoService.adicionarPsicologo();
    // console.log(this.firstFormGroup.value)
    // console.log(this.secondFormGroup.value);
    // this.pessoa1 = this.firstFormGroup.value;
    // this.pessoa2 = this.secondFormGroup.value;
    // this.pessoa =  Object.assign(this.pessoa1, this.pessoa2);
    // console.log(this.pessoa1.cpf);
    // console.log(this.pessoa.cpf);
    // this.onAdicionarPessoa();
  }

  montaQuestionario(): void{
    this.questionario = this.thirdFormGroup.value;
    // console.log(this.questionario);
    if(this.route.snapshot.paramMap.get('id')){
      this.onAlterarQuestionario();
    }else{
      this.onAdicionarQuestionario();
    }
  }

  onAdicionarPessoa(): void {
    this.questionarioService.adicionarPessoa(this.pessoa).subscribe(resultado => this.pessoa = resultado);
    // console.log(this.pessoa.cpf);
    // this.psicologoService.get(resultado => this.consultoriosParaHorario = resultado);
 }

  onAdicionarQuestionario(): void {
    this.psicologoService.adicionarQuestionario(this.questionario).subscribe();
    // console.log(this.questionario.idPessoa);
 }

 onAlterarQuestionario(): void {
  this.questionario.idQuestionario = Number(this.route.snapshot.paramMap.get('id'));
  this.psicologoService.alterarQuestionario(this.questionario).subscribe();
 }
}


