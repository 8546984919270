export class Cep {
  cep?: string;
  logradouro?: string;
  nResid?: string;
  complemento?: string;
  uf?: string;
  localidade?: string;
  bairro?: string;

  erro?: string;
  error?: string;

  privado?: Boolean;

}
