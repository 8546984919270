import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Consultorio } from '../psicologo/consultorio.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { QuestionarioService } from '../questionario/questionario.service';
import { Cep } from './cep.model';

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.css']
})
export class EnderecoComponent implements OnInit {

  @Input() questionario;
  @Input() psicologo;
  isLinear = false;
  public secondFormGroup: FormGroup;
  public formGroup: FormGroup;
  public botao: boolean = true;
  public cep = new Cep;
  public idEndereco: number = null;


  estado :Array<{id:number, option:string}> = [
    {id:1, option:"SP"},
    {id:2, option:"RJ"},
    {id:3, option:"MG"},
    {id:4, option:"DF"},
    {id:5, option:"SC"},
    {id:6, option:"BA"}
  ]

  cidade :Array<{id:number, option:string}> = [
    {id:1, option:"São Paulo"},
    {id:2, option:"Vargem Grande Paulista"},
    {id:3, option:"Mauá"},
    {id:4, option:"São Caetano"}
  ]



  constructor(public psicologoService: PsicologoService , private formBuilder: FormBuilder, private route: ActivatedRoute, public questionarioService: QuestionarioService, private router: Router ) { }

  ngOnInit(): void {
    this.secondFormGroup = this.formBuilder.group({
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      numeroR: ['', Validators.required],
      complemento: [''],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: [''],
      idEndereco: ['']
    });

    const id = this.route.snapshot.paramMap.get('id');
    if(id && this.router.url.includes('/psicologo')){
      this.psicologoService.getPsicologoById(Number(id)).subscribe(q => {this.psicologo = q;
        this.secondFormGroup.patchValue({"idEndereco": this.psicologo.endereco.idEndereco}); this.secondFormGroup.get('idEndereco').updateValueAndValidity();
        this.secondFormGroup.patchValue({"cep": this.psicologo.endereco.cep}); this.secondFormGroup.get('cep').updateValueAndValidity();
        this.secondFormGroup.patchValue({"logradouro": this.psicologo.endereco.logradouro}); this.secondFormGroup.get('logradouro').updateValueAndValidity();
        this.secondFormGroup.patchValue({"numeroR": this.psicologo.endereco.numeroR}); this.secondFormGroup.get('numeroR').updateValueAndValidity();
        this.secondFormGroup.patchValue({"bairro": this.psicologo.endereco.bairro}); this.secondFormGroup.get('bairro').updateValueAndValidity();
        this.secondFormGroup.patchValue({"complemento": this.psicologo.endereco.complemento}); this.secondFormGroup.get('complemento').updateValueAndValidity();
        this.secondFormGroup.patchValue({"estado": this.psicologo.endereco.estado}); this.secondFormGroup.get('estado').updateValueAndValidity();
        this.secondFormGroup.patchValue({"cidade": this.psicologo.endereco.cidade}); this.secondFormGroup.get('cidade').updateValueAndValidity();
      });
    }else if(id)
    {
        this.questionarioService.getQuestionarioById(id).subscribe(q => {this.questionario = q;
        this.idEndereco = this.questionario.pessoa.endereco.idEndereco;
        this.secondFormGroup.patchValue({"idEndereco": this.questionario.pessoa.endereco.idEndereco}); this.secondFormGroup.get('idEndereco').updateValueAndValidity();
        this.secondFormGroup.patchValue({"cep": this.questionario.pessoa.endereco.cep}); this.secondFormGroup.get('cep').updateValueAndValidity();
        this.secondFormGroup.patchValue({"logradouro": this.questionario.pessoa.endereco.logradouro}); this.secondFormGroup.get('logradouro').updateValueAndValidity();
        this.secondFormGroup.patchValue({"numeroR": this.questionario.pessoa.endereco.numeroR}); this.secondFormGroup.get('numeroR').updateValueAndValidity();
        this.secondFormGroup.patchValue({"bairro": this.questionario.pessoa.endereco.bairro}); this.secondFormGroup.get('bairro').updateValueAndValidity();
        this.secondFormGroup.patchValue({"complemento": this.questionario.pessoa.endereco.complemento}); this.secondFormGroup.get('complemento').updateValueAndValidity();
        this.secondFormGroup.patchValue({"estado": this.questionario.pessoa.endereco.estado}); this.secondFormGroup.get('estado').updateValueAndValidity();
        this.secondFormGroup.patchValue({"cidade": this.questionario.pessoa.endereco.cidade}); this.secondFormGroup.get('cidade').updateValueAndValidity();
      });
  }

  }


  onAdicionarEndereco(){
    // const id = this.route.snapshot.paramMap.get('id');
    // if(id)
    // {
    //   this.psicologoService.alteraEndereco(this.secondFormGroup.value);
    // }else {
      this.psicologoService.adicionaEndereco(this.secondFormGroup.value);
    // }
 }



 verificaCEP(event: any): void {
   let cep = event.target.value;

   if (cep != null){
    this.psicologoService.verificaCEP(cep).subscribe(resultado => {
      if (resultado){
      this.secondFormGroup.setValue({
        idEndereco: this.idEndereco ,
        cep: resultado.cep.replace('-', ''),
        logradouro: resultado.logradouro,
        numeroR: '',
        complemento: '',
        estado: resultado.uf,
        cidade: resultado.localidade,
        bairro: resultado.bairro,
    });
  }else{
    this.secondFormGroup.setValue({
      idEndereco: this.idEndereco ,
      cep: resultado.cep.replace('-', ''),
      logradouro: "CEP inválido",
      numeroR: '',
      complemento: '',
      estado: 'CEP inválido',
      cidade: 'CEP inválido',
      bairro: 'CEP inválido',
    });
  }
  }
    );

}

}

//  entraInfosCep(){
//   this.secondFormGroup.setValue({
//     logradouro: this.cep.logradouro,
//   });
//  }

}
