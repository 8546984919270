<mat-toolbar class="header mat-elevation-z4">
  <mat-toolbar-row>
    <a class="logo" href="#" routerLink="/">Triagem +</a>
  </mat-toolbar-row>
</mat-toolbar>

<div class="canvasLogin">
  <mat-card class="card">
    <div class="title">
      Recuperar Senha
    </div>
    <form (submit)="onSubmit();" #loginForm="ngForm">
      <mat-form-field class="trintaEtres">
        <input type="email" required matInput placeholder="Login" [(ngModel)]="username" name="username" class="username" autocomplete="off" [email]="true" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
        <mat-error>Digite um e-mail válido</mat-error>
      </mat-form-field>
      <mat-error *ngFor="let erro of errors" class="loginError">
        {{ erro }}
      </mat-error>

      <button mat-raised-button *ngIf="!cadastrando" [disabled]="loginForm.invalid">Enviar</button>


    </form>


      <div class="link">
        <a (click)="voltar()" href="#">Cancelar</a>
      </div>


  </mat-card>
</div>
