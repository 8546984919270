
    <h2>Endereço:</h2>

    <mat-card>
      <form (ngSubmit) = "onAdicionarPsicologoEndereco()" [formGroup]="formGroup">

        <mat-form-field class="dez">
          <input type="text" minlength="5" matInput placeholder="CEP" name="cep" formControlName="cep">
          <mat-error *ngIf="formGroup.controls['cep'].invalid">Digite um CEP válido</mat-error>
        </mat-form-field>

        <mat-form-field class="setenta">
          <input type="text," minlength="3" matInput placeholder="logradouro" name="logradouro" formControlName="logradouro">
          <mat-error *ngIf="formGroup.controls['logradouro'].invalid">Logradouro com 3 ou mais letras</mat-error>
        </mat-form-field>

        <mat-form-field class="dez">
          <input type="text" pattern="[0-9]*" matInput placeholder="número" name="nResid" formControlName="nResid">
          <mat-error *ngIf="formGroup.controls['nResid'].invalid">Digite um número válido</mat-error>
        </mat-form-field>

        <mat-form-field class="trintaEtres">
          <input type="text" matInput placeholder="complemento" name="complemento" formControlName="complemento">
          <mat-error *ngIf="formGroup.controls['complemento'].invalid">Digite um complemento válido</mat-error>
        </mat-form-field>


      <mat-form-field class="trintaEtres">
        <input type="text" matInput placeholder="bairro" name="bairro" formControlName="bairro">
        <mat-error *ngIf="formGroup.controls['bairro'].invalid">Digite um bairro válido</mat-error>
      </mat-form-field>

        <mat-form-field class="trinta">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="estado">
            <mat-option *ngFor="let est of estado" [value]="est.option" name="estado">
              {{est.option}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls['estado'].invalid">Escolha um estado válido</mat-error>

        </mat-form-field>
        <mat-form-field class="setenta">
          <mat-label>Cidade</mat-label>
          <mat-select formControlName="cidade">
            <mat-option *ngFor="let cid of cidade" [value]="cid.option" name="cidade">
              {{cid.option}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls['cidade'].invalid">Escolha uma cidade válida</mat-error>
        </mat-form-field>


        <button color="primary" mat-raised-button [disabled]="formGroup.invalid">Cadastrar Endereço</button>

      </form>

  </mat-card>
