<!-- <mat-toolbar color="primary">
  <ul>
    <li><a routerLink="/">Login</a></li>
    <li><a routerLink="/psicologoInserir">Novo Psicólogo</a></li>
    <li><a routerLink="/psicologoInserirEndereco">Novo Psicólogo Endereco</a></li>
  </ul>
</mat-toolbar> -->


<mat-toolbar class="header mat-elevation-z4">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleRightSidenav()" fxShow="true" >
      <!-- fxHide.gt-sm -->
      <mat-icon>menu</mat-icon>
    </button>
    <a class="logo" href="#" routerLink="/">T.B.I</a>
    <span class="menu-spacer"></span>
    <div fxShow="true" >
      <!-- fxHide.lt-md -->
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <!-- <a mat-button routerLink="/">Login</a>
      <a mat-button routerLink="/psicologoInserir">Novo Psicólogo</a>
      <a mat-button routerLink="/consultorio">Consultórios</a> -->
      <a mat-button (click)="logout()" href="#"><i class="material-icons">cancel</i></a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>



