import { Horario } from "../horarios/horario.model";
import { Consultorio } from "./consultorio.model";

export class Psicologo {
  nome?: string;
  sobrenome?: string;
  fone?: string;
  email?: string;
  cpf?: number;
  dataNasc?: any;
  terapeuta?: boolean = false;
  escolaridade?: string;
  cep?: string;
  logradouro?: string;
  numeroR?: string;
  complemento?: string;
  estado?: string;
  cidade?: string;
  bairro?: string;
  idPessoa?: number;
  consultorios?: Array<Consultorio>;
  horarios?: Array<Horario>;
  consultoriosIds: Array<number>;
  idEndereco?: number;
  concordo?: boolean;

  // de novos Bancos
  createdAt?: string;
}
