<h2>Terapeutas</h2>



<form [formGroup]="seventhFormGroup">
  <mat-accordion>
    <div *ngFor="let psicologo of psicologos; let i = index">

      <!-- <mat-checkbox (change)="verify($event.checked, questionarios.idQuestionario)" class="example-margin" formControlName="{{'check-' + i}}"></mat-checkbox> -->
      <mat-expansion-panel style="display: inline-table;width: 100%;">

        <mat-expansion-panel-header>

          <i class="material-icons person">person</i>
          <div class="nomeQuestionarioRead">{{psicologo.nome}} {{psicologo.sobrenome}}</div>


          <!-- <div class="action">
              <ng-container  matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell>
                <a (click)="getPsicologo(psicologo.idPessoa)" class="edit">
                  <i class="material-icons">
                      edit
                  </i>
              </a>
              <a (click)="openDialog(psicologo.idPessoa)"   class="delete">
                  <i class="material-icons">
                      delete
                  </i>
              </a>
              </td>
            </ng-container>
    </div> -->

    <div class="navHor">
      <ul>
    <li class="about">
      <a (click)="getPsicologo(psicologo.idPessoa)" class="edit">
        <i class="material-icons">edit</i>
        <b>Editar</b>
      </a>
    </li>
    <li class="delete">
      <a (click)="openDialog(psicologo.idPessoa)"   class="delete">
        <i class="material-icons">delete</i>
        <b>Deletar</b>
      </a>
    </li>
</ul>
</div>

        </mat-expansion-panel-header>

    <div>
        <h3>Dados Pessoais</h3>
        <div class="retangulo">
          <table>
            <tr>
              <th>Id</th>
              <th>Cpf</th>
              <th>Telefone</th>
              <th> </th>
            </tr>
            <tr>
              <td *ngIf="psicologo.idPessoa">{{psicologo.idPessoa}}</td>
              <td *ngIf="psicologo.cpf">{{psicologo.cpf}}</td>
              <td *ngIf="psicologo.telefone">{{psicologo.telefone}}</td>
              <td> </td>
            </tr>
          </table>
          <table >
            <tr>
              <th>E-mail</th>
              <th>Escolaridade</th>
              <th>Nascimento</th>
              <th>Data Cadastro</th>
            </tr>
            <tr>
              <td>{{psicologo.email}}</td>
              <td *ngIf="psicologo.escolaridade">{{psicologo.escolaridade.escolaridade}}</td>
              <td *ngIf="psicologo.dataNasc">{{psicologo.dataNasc}}</td>
              <td>{{psicologo.createdAt | slice:0:10}}</td>
            </tr>
          </table>
        </div>
      </div>



      <div>
        <h3 *ngIf="psicologo.endereco">Endereço</h3>
        <div class="retangulo" *ngIf="psicologo.endereco">
          <table>
            <tr>
              <th>Cep</th>
              <th>Logradouro</th>
              <th>Numero</th>
              <th> </th>
            </tr>
            <tr>
              <td *ngIf="psicologo.endereco.cep">{{psicologo.endereco.cep}}</td>
              <td *ngIf="psicologo.endereco.logradouro">{{psicologo.endereco.logradouro}}</td>
              <td *ngIf="psicologo.endereco.numeroR">{{psicologo.endereco.numeroR}}</td>
            </tr>
          </table>
          <table >
            <tr>
              <th>Complemento</th>
              <th>Bairro</th>
              <th>Estado</th>
              <th>Cidade</th>
            </tr>
            <tr>
              <td *ngIf="psicologo.endereco.complemento">{{psicologo.endereco.complemento}}</td>
              <td *ngIf="psicologo.endereco.bairro">{{psicologo.endereco.bairro}}</td>
              <td *ngIf="psicologo.endereco.estado">{{psicologo.endereco.estado}}</td>
              <td *ngIf="psicologo.endereco.cidade" >{{psicologo.endereco.cidade}}</td>
            </tr>
          </table>
        </div>
      </div>


      <!-- <mat-card> -->
        <h3 *ngIf="psicologo.agenda">Horários disponíveis</h3>

        <div class="retangulo" *ngIf="psicologo.agenda">
        <table  *ngFor="let agenda of psicologo.agenda; let i = index">

          <ng-container matColumnDef="consultorios">
            <!-- <th mat-header-cell > Dia </th> -->
            <td mat-cell > {{agenda.diaSemana.descricao}}  </td>
          </ng-container>

          <ng-container matColumnDef="consultorios">
            <!-- <th mat-header-cell > Hora Início </th> -->
            <td mat-cell > {{agenda.horaInicio}} </td>
          </ng-container>

          <ng-container matColumnDef="consultorios">
            <!-- <th mat-header-cell > Hora Fim </th> -->
            <td mat-cell > {{agenda.horaFim}} </td>
          </ng-container>

          <ng-container matColumnDef="consultorios">
            <!-- <th mat-header-cell > Hora Fim </th> -->
            <td mat-cell >{{agenda.consultorio.nomeConsultorio}} </td>
          </ng-container>




          <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        </table>
      </div>

      <!-- </mat-card> -->


</mat-expansion-panel>
    </div>
  </mat-accordion>
</form>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements" (page)="nextPage($event)"> </mat-paginator>

<mat-card style="margin-top: 32px;">
  <button (click)="novoTerapeuta()" mat-button class="novo">Novo Terapeuta</button>
</mat-card>

