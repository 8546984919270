<mat-toolbar class="header">
  <mat-toolbar-row>
    <a class="logo" href="#" routerLink="/">Triagem +</a>
    <span class="menu-spacer"></span>
    <div fxShow="true" >
      <a mat-button  href="#"><i class="material-icons" routerLink="/login">login</i>  entrar</a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="total">
  <div>
    <div class="imgPrincipal">
      <img src="./assets/5215393.png" alt="">
    </div>
    <div class="secundaria" >
      <div>
      <img src="./assets/relaxed.png" alt="">
      </div>
      <div class="texto">
        <h1>Bem-vindo !</h1>
        <h4>Cadastre-se e agende um entrevista para
          triagem.
          Passsando pelo processo, você poderá ter um
          tratamento com um terapeuta.</h4>
          <a mat-button class="formularioBotao"  href="#" routerLink="/questionarioClient"><i class="material-icons ">content_paste</i>  formulário</a>
      </div>
    </div>
  </div>


</div>
