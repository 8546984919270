<mat-toolbar class="header mat-elevation-z4">
  <mat-toolbar-row>
    <a class="logo" href="#" routerLink="/index">T.B.I</a>
  </mat-toolbar-row>
</mat-toolbar>

<div class="canvas">
  <h2>QUESTIONÁRIO</h2>

    <mat-card>
    <mat-horizontal-stepper [linear]="isLinear" #stepper>

      <mat-step >
        <ng-template matStepLabel>Termos de uso e política de privacidade</ng-template>
          <app-termo></app-termo>
          <!-- <div>
            <button mat-button matStepperNext>Próximo</button>
          </div> -->
      </mat-step>

      <mat-step [stepControl]="firstFormGroup">
        <ng-template matStepLabel>Dados Pessoais</ng-template>
          <app-dados-pessoais></app-dados-pessoais>
          <!-- <div>
            <button mat-button matStepperNext>Próximo</button>
          </div> -->
      </mat-step>


      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>Endereço</ng-template>
        <app-endereco></app-endereco>
        <!-- <div>
          <button mat-button matStepperNext (click)="montarPessoa()" >PróximoMontar</button>
        </div> -->
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Data e hora</ng-template>
        <h2>Selecione dia e período preferido para uma entrevista:</h2>
        <mat-form-field class="trintaEtres" >
          <mat-label>dia da semana preferido</mat-label>
          <mat-select>
            <mat-option *ngFor="let dia of diaSemana" [value]="dia.id" (click)="setDia(dia.id)">
              {{dia.option}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="trintaEtres" >
          <mat-label>período para uma entreviata</mat-label>
          <mat-select>
            <mat-option *ngFor="let per of periodo" [value]="per.id" (click)="setPeriodo(per.id)">
              {{per.option}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div style="display: flex; justify-content: space-evenly;">
          <button mat-button matStepperPrevious>Voltar</button>
          <button mat-button matStepperNext (click)="montaQuestionario()">Salvar</button>
        </div>
      </mat-step>


      <mat-step>
        <ng-template matStepLabel>Fim</ng-template>
        <p>Cadastro Salvo!</p>
      </mat-step>

      <!-- <div style="display: flex; justify-content: space-evenly;">
        <button mat-button matStepperPrevious>Voltar</button>
        <button mat-button matStepperNext >Salvar</button>
      </div> -->

    </mat-horizontal-stepper>

  </mat-card>
</div>
