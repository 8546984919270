import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Consultorio } from '../psicologo/consultorio.model';
import { Horario } from './horario.model';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { PsicologoService } from '../psicologo/psicologo.service';
import { DiaSemana } from './dia-semana.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Psicologo } from '../psicologo/psicologo.model';
import { QuestionarioService } from '../questionario/questionario.service';
import { Questionario } from '../questionario/questionario.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.css']
})
export class HorariosComponent implements OnInit, AfterViewInit {


  @ViewChild(MatSort) sort: MatSort;
  @Input() consultoriosParaHorario?: Array<Consultorio> =[];



  displayedColumns: string[] = ['consultorios', 'diaSemana', 'horaInicio', 'horaFim', 'excluir'];

  // sortedData: Horario[];
  ELEMENT_DATA = new Array<Horario>();
  dataSource = new MatTableDataSource();
  @ViewChild(MatTable) table: MatTable<Horario>;
  consultorio: Consultorio = new Consultorio();

  public fourthFormGroup: FormGroup;
  horarios = new Array<Horario>();
  horario: Horario;

  consultorios: Array<Consultorio> = [];

  isHorarioInserir: boolean = true;
  public pessoa = new Psicologo;


  usuarioLogado: string;
  roles: string;
  id:number;



  semana: Array<DiaSemana> = [
    {idDiaSemana:1, descricao:"domingo"},
    {idDiaSemana:2, descricao:"segunda-feira"},
    {idDiaSemana:3, descricao:"terça-feira"},
    {idDiaSemana:4, descricao:"quarta-feira"},
    {idDiaSemana:5, descricao:"quinta-feira"},
    {idDiaSemana:6, descricao:"sexta-feira"},
    {idDiaSemana:7, descricao:"sábado"},
  ];


  constructor(private psicologoService: PsicologoService, private formBuilder:FormBuilder, public dialog: MatDialog,
    private router: Router, private questionarioService: QuestionarioService, private authService: AuthService) {
    this.fourthFormGroup = this.formBuilder.group({
      semana: ['', Validators.required],
      consultorios: ['', Validators.required],
      horaInicio: ['', Validators.required],
      horaFim: ['', Validators.required],
    });

    // this.sortedData = this.ELEMENT_DATA;
  }

  ngOnInit(): void {
    this.usuarioLogado = this.authService.getUsuarioAutenticado();
    this.psicologoService.getPsicologoByEmail(this.usuarioLogado).subscribe(resultado =>{
      this.pessoa.idPessoa = resultado.idPessoa;
    this.psicologoService.getConsultoriosByPsicologo(resultado.idPessoa).subscribe(q => {this.consultorios = q});
    this.psicologoService.getHorariosCRUD(resultado).subscribe(q => {this.ELEMENT_DATA = q, this.dataSource.data = this.ELEMENT_DATA});
    });
    if(this.router.url === '/horario'){
      this.psicologoService.getHorariosCRUD(this.pessoa).subscribe(q => {this.ELEMENT_DATA = q, this.dataSource.data = this.ELEMENT_DATA});
    }else{
      this.psicologoService.getHorarios().subscribe(q => {this.ELEMENT_DATA = q, this.dataSource.data = this.ELEMENT_DATA});
    }
    // this.dataSource.data = this.ELEMENT_DATA;
    // this.getConsultoriosPessoa();
    this.isHorarioInserirShow();
  }

  isHorarioInserirShow(){
    if(this.router.url === '/horario'){
      this.isHorarioInserir = true;
    }else{
      this.isHorarioInserir = false;
    }
  }

  // getConsultoriosPessoa(){
  //   this.psicologoService.getConsultoriosByPsicologo(this.pessoa.idPessoa).subscribe(q => {this.consultorios = q, console.log(this.consultorios)});
  // }

  // updateHorario(){
  //   if(this.router.url === '/horario'){
  //     this.psicologoService.getHorariosCRUD(this.pessoa).subscribe(q => {this.ELEMENT_DATA = q, console.log(this.ELEMENT_DATA)});
  //   }else{
  //     this.psicologoService.getHorarios().subscribe(resultado => this.ELEMENT_DATA = resultado);
  //   }

  // }

  onEnviarHorario(){
    this.horarios.forEach(horario => {
      horario.pessoa.idPessoa = this.pessoa.idPessoa;
    });
    this.psicologoService.adicionarHorario(this.horarios).subscribe();
    window.location.reload();
    this.horarios=[];
  }

  onEnviarHorarioCRUD(){
    this.horarios.forEach(horario => {
      horario.pessoa.idPessoa = this.pessoa.idPessoa;
    });
    this.psicologoService.adicionarHorarioCRUD(this.horarios).subscribe(() => {this.table.renderRows(); window.location.reload(); });
    this.horarios=[];
  }




  onAdicionarHorario(){
    // if(this.router.url === '/horario'){
      this.consultorio.idConsultorio = this.fourthFormGroup.value.consultorios;
      let horario: Horario = new Horario();
      for ( let item of this.consultorios) {
        if ( item.idConsultorio === this.consultorio.idConsultorio){
          horario.consultorio = item;
          // console.log(horario.consultorio);
          horario.horaFim = this.fourthFormGroup.value.horaFim;
          horario.horaInicio = this.fourthFormGroup.value.horaInicio;
          horario.diaSemana.idDiaSemana = this.fourthFormGroup.value.semana;
          for ( let item of this.semana) {
            if ( item.idDiaSemana === horario.diaSemana.idDiaSemana){
              horario.diaSemana.descricao = item.descricao;
            }
          }
        }
      }
      this.horarios.push(horario);
      // this.dataSource.data.push(horario);
      // this.dataSource.data = this.dataSource.data;
      // console.log(this.dataSource.data);
      this.onEnviarHorarioCRUD();
      this.fourthFormGroup.patchValue({
        semana: ' ',
        consultorios: ' ',
        horaInicio: '',
        horaFim: '',
      });

}

  openDialog(index: number): void {
    this.questionarioService.idDelHorario = index;
    this.questionarioService.pessoa= this.pessoa;
    // this.dataSource.data.splice(index,1);
    // window.location.reload();
    const dialogRef = this.dialog.open(DialogHorarioDeleteComponent, {
      height: '250px',
      width: '400px',});

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      console.log(" ")
    });
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  // sortedData;

  // sortData(sort: Sort) {

  //   if (!sort.active || sort.direction === '') {
  //     this.sortedData = this.dataSource.data;
  //   } else {
  //     this.sortedData = this.dataSource.data.sort((a, b) => {
  //       const aValue = (a as any)[sort.active];
  //       const bValue = (b as any)[sort.active];
  //       return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
  //     });
  //   }
  // }


update(){
  // this.consultoriosParaHorario = this.psicologoService.pessoa.consultorios;
  // this.consultorios = this.consultoriosParaHorario;
  // console.log(this.consultoriosParaHorario);
  // console.log(this.consultorios);

  this.psicologoService.getConsultoriosByPsicologo(this.pessoa.idPessoa).subscribe(resultado => this.consultorios = resultado);
  // console.log(this.consultorios);
  // this.psicologoService.atualizaConsultodeDePsicologo(this.consultorios);
}

}



@Component({
  selector: 'dialog-horario-delete-component',
  templateUrl: 'dialog-horario-delete-component.html',
})
export class DialogHorarioDeleteComponent implements OnInit{


  horario: Horario;
  id: number = this.questionarioService.idDelHorario;

  constructor(private questionarioService: QuestionarioService, private psicologoService: PsicologoService) { }

  ngOnInit(): void {
    this.questionarioService.readByIdHorario(this.id).subscribe((horario) => {
      this.horario = horario;
    });
  }



  delete(): void  {
    this.questionarioService.deleteHorario(this.questionarioService.idDelHorario).subscribe(
      () => this.psicologoService.getConsultorioByPessoa(this.questionarioService.pessoa));
    window.location.reload();

}



}
