import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PsicologoInserirComponent } from './psicologo-inserir/psicologo-inserir.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar' ;
import { MatExpansionModule } from '@angular/material/expansion';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { LoginComponent } from './login/login.component';
import { PsicologoInserirEnderecoComponent } from './psicologo-inserir-endereco/psicologo-inserir-endereco.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { ConsultoriosComponent, DialogConsultorioDeleteComponent } from './consultorios/consultorios.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { MatHorizontalStepper, MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import {MatRadioButton, MatRadioGroup, MatRadioModule} from '@angular/material/radio';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DadosPessoaisComponent, DialogCpf } from './dados-pessoais/dados-pessoais.component';
import { EnderecoComponent } from './endereco/endereco.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { DialogHorarioDeleteComponent, HorariosComponent } from './horarios/horarios.component';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { NavComponent } from './nav/nav.component';
import { DialogCancelaTriagemComponent, DialogQuestionarioDeleteComponent, QuestionarioReadComponent } from './questionario/questionario-read/questionario-read.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogPsicologoDeleteComponent, PsicologoReadComponent } from './psicologo-inserir/psicologo-read/psicologo-read.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from './login/login.service';
import { LayoutComponent } from './layout/layout.component';
import { AuthService } from './auth.service';
import { TokenInterceptor } from './token.interceptor';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import {NgxMaskModule} from 'ngx-mask';
import { EmailEnviado, LoginRecuperarComponent } from './login-recuperar/login-recuperar.component';
import { LoginSenhaComponent } from './login-senha/login-senha.component';
import { IndexComponent } from './index/index.component';
import { LoginCadastroComponent } from './login-cadastro/login-cadastro.component';
import { QuestionarioClientComponent } from './questionario-client/questionario-client.component';
import { TermoComponent } from './termo/termo.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EncaminhamentoComponent } from './encaminhamento/encaminhamento.component';
import { TerapiaComponent } from './terapia/terapia.component';
import { DialogTerapiaDeleteComponent, TerapiaReadComponent } from './terapia/terapia-read/terapia-read.component';
import { RelatorioComponent } from './relatorio/relatorio.component';
import { Segmento1Component } from './segmento1/segmento1.component';
import { Segmento2Component } from './segmento2/segmento2.component';
import { SegmentoFinalComponent } from './segmento-final/segmento-final.component';


@NgModule({
  declarations: [
    AppComponent,
    PsicologoInserirComponent,
    CabecalhoComponent,
    PsicologoInserirEnderecoComponent,
    ConsultoriosComponent,
    QuestionarioComponent,
    DadosPessoaisComponent,
    EnderecoComponent,
    HorariosComponent ,
    ConsultoriosComponent,
    NavComponent,
    QuestionarioReadComponent,
    DialogQuestionarioDeleteComponent,
    PsicologoReadComponent,
    DialogPsicologoDeleteComponent,
    DialogHorarioDeleteComponent,
    DialogConsultorioDeleteComponent,
    DialogCpf,
    EmailEnviado,
    LoginComponent,
    LayoutComponent,
    AgendamentoComponent,
    DialogCancelaTriagemComponent,
    LoginRecuperarComponent,
    LoginSenhaComponent,
    IndexComponent,
    LoginCadastroComponent,
    QuestionarioClientComponent,
    TermoComponent,
    EncaminhamentoComponent,
    TerapiaComponent,
    TerapiaReadComponent,
    DialogTerapiaDeleteComponent,
    RelatorioComponent,
    Segmento1Component,
    Segmento2Component,
    SegmentoFinalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule ,
    FormsModule ,
    BrowserAnimationsModule ,
    MatInputModule ,
    MatCardModule ,
    MatButtonModule ,
    MatToolbarModule ,
    MatExpansionModule ,
    MatDatepickerModule ,
    MatNativeDateModule ,
    MatSelectModule ,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatStepperModule,
    MatRadioModule,
    MatFormFieldModule ,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    MatPaginatorModule
  ],
  providers: [
    MatDatepickerModule ,
    MatStep ,
    MatHorizontalStepper,
    MatRadioButton ,
    MatRadioGroup ,
    LoginService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
