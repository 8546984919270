import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { tap } from 'rxjs/operators';

import { Questionario } from '../questionario.model';
import { QuestionarioService } from '../questionario.service';
import { PageEvent } from '@angular/material/paginator';
import { Terapia } from 'src/app/terapia/terapia.model';
import { AuthService } from 'src/app/auth.service';


@Component({
  selector: 'app-questionario-read',
  templateUrl: './questionario-read.component.html',
  styleUrls: ['./questionario-read.component.css']
})
export class QuestionarioReadComponent implements OnInit {

  public questionarios: Array<Questionario>;
  public questionariosFiltered: Array<Questionario> = Array<Questionario>();
  displayedColumns = ['idQuestionario']
  public idDel: number;
  questionario: Questionario;
  public sixFormGroup: FormGroup;
  isAgendado=false;
  public agendamentoTriagem: Array<Questionario>;
  totalElements: number = 0;
  questionariosInicial: Array<Questionario>;
  terapia: Terapia;
  idPessoa: number;
  ehAdmin: boolean = false;
  roles: string;


  status: Array<{id:number, option:string}> = [
    {id:1, option:"TODOS"},
    {id:2, option:"AGENDAR"},
    {id:3, option:"AGENDADO"},
    {id:3, option:"CONCLUIDO"}
  ];

  constructor(public questionarioService: QuestionarioService, public dialog: MatDialog, public router: Router, private formBuilder: FormBuilder, private changeDetection: ChangeDetectorRef, private route: ActivatedRoute, private authService: AuthService) {
    this.sixFormGroup = this.formBuilder.group({});
   }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id')){
      this.onListarQuestionarioTerapeuta(Number(this.route.snapshot.paramMap.get('id')));
    }else {
      this.onListarQuestionario({ page: "0", size: "5" });
    }

    this.pegaRoles();
  }

  pegaRoles(){
    this.roles = this.authService.obterRoles();
    if(this.roles == "ROLE_ADMIN"){
      this.ehAdmin = true;
      return true;
    }
    return false;
  }


  public onListarQuestionario(request): void{
    this.questionarioService.getQuestionarios(request).subscribe(resultado => {
      this.questionarios = resultado['content'],
      this.questionariosFiltered = this.questionarios,
      this.totalElements = resultado['totalElements'];
      this.questionariosInicial = resultado['content'];
      // console.log(this.questionarios);

      for (let q of this.questionarios){
        if(q.idAgendamentoTriagem!=null){
          q.agendado = true;
        }
      }

    });

  }

  nextPage(event: PageEvent) {
    const request = {};
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.onListarQuestionario(request);
  }

  public onListarQuestionarioTerapeuta(id): void{
    this.questionarioService.getQuestionariosTerapeuta(id).subscribe(resultado => {
      this.questionarios = resultado, this.questionariosFiltered = this.questionarios;
      for (let q of this.questionarios){
        if(q.idAgendamentoTriagem!=null){
          q.agendado = true;
        }
      }
    });
    // console.log(this.questionarios);
  }


  openDialog(id: number): void {
    this.questionarioService.idDel = id;
    const dialogRef = this.dialog.open(DialogQuestionarioDeleteComponent, {
      height: '250px',
      width: '400px',});

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  getQuestionario(id): void{
      this.router.navigate(['/questionario/' + id]);
    }

  vaiAgendar(id, idQuestionario): void{
    this.router.navigate(['/agendamento/' + id]);
    this.questionarioService.questionarioParaAgendamento = idQuestionario;
    this.questionarioService.isAlterar = false;
  }

    // console.log(this.questionario);
    // console.log(this.questionario);

    onFilterStatus(value: string){
      if ('TODOS' === value){
        window.location.reload();
      }
      this.questionarioService.getQuestionarios({ page: "0", size: "2000" }).subscribe(resultado => {
        this.questionarios = resultado['content'],
        this.totalElements = resultado['totalElements'];
        for (let q of this.questionarios){
          if(q.idAgendamentoTriagem!=null){
            q.agendado = true;
          }
        }

        this.questionariosFiltered = [],

        this.questionarios.filter((a) => {
          if (a.status === value){
            this.questionariosFiltered.push(a);
          }
          // this.changeDetection.detectChanges(),
        });
      });
    }

    public trackItem (index: number, item: Questionario) {
      return item.idQuestionario;
    }

    // public getAgendamentoTriagem(id): void{
    //   this.questionarioService.getAgendamentoTriagem(id).subscribe(resultado => {this.agendamentoTriagem = resultado, console.log(this.agendamentoTriagem)});
    //   console.log(this.questionarios);
    // }

    // public editAgendamentoTriagem(id): void{
    //   this.router.navigate(['/agendamento/' + id]);
    // }

    openDialogDeleteTriagem(id: number): void {
      this.questionarioService.idDelTriagem = id;
      const dialogRef = this.dialog.open(DialogCancelaTriagemComponent, {
        height: '250px',
        width: '400px',});

      dialogRef.afterClosed().subscribe(result => {
        // console.log(`Dialog result: ${result}`);
        console.log(" ");
      });
    }

    alterarTriagem(id, idQuestionario): void{
      this.questionarioService.isAlterar = true;
      this.router.navigate(['/agendamento/' + id]);
      this.questionarioService.questionarioParaAgendamento = idQuestionario;
    }

    avaliar(id:number):void{
      this.questionarioService.getQuestionarioById(id).subscribe(resultado => {
        this.questionario = resultado;
        if (resultado.concluido == false){
          this.questionario.concluido = true;
          this.questionarioService.avaliarConcluir(id).subscribe(result =>{
            this.questionario.status= "CONCLUÍDO", this.questionario.concluido = true});
        }else{
        this.questionario.concluido = false;
        this.questionarioService.desfazerConcluir(id).subscribe(result =>{
          this.questionario.status= "AGENDADO", this.questionario.concluido = false});
      }
    });
      window.location.reload();
    }

  concluir(id:number):void{
      this.questionarioService.getQuestionarioById(id).subscribe(resultado => {
        this.questionario = resultado
    });
    this.questionarioService.avaliarConcluir(id).subscribe(result =>{
      this.questionario.status= "CONCLUÍDO", this.questionario.concluido = true});
    window.location.reload();
  }

  retomar(id:number):void{
    this.questionarioService.getQuestionarioById(id).subscribe(resultado => {
      this.questionario = resultado
  });
  this.questionarioService.desfazerConcluir(id).subscribe(result =>{
    this.questionario.status= "AGENDADO", this.questionario.concluido = false});
  window.location.reload();
  }

  encaminhar(id:number):void{
    this.router.navigate(['/encaminhamento/' + id]);
  }

  getSegmentoFinal(id):void {
    this.questionario = new Questionario;

    this.questionarioService.getQuestionarioById(id).subscribe(result =>{
      this.questionario = result;
      this.idPessoa = result.pessoa.idPessoa;
      this.terapia = new Terapia;
      this.questionarioService.getTerapiaByPessoa(this.idPessoa).subscribe(result2 =>{
        this.terapia = result2;
        this.router.navigate(['/terapia/segmentoFinal/' + this.terapia.idTerapia]);
      });
    });


  }

}



@Component({
  selector: 'dialog-questionario-delete-component',
  templateUrl: 'dialog-questionario-delete-component.html',
})
export class DialogQuestionarioDeleteComponent implements OnInit{


  questionario: Questionario;
  id: number = this.questionarioService.idDel;

  constructor(private questionarioService: QuestionarioService) { }

  ngOnInit(): void {
    this.questionarioService.readById(this.id).subscribe((questionario) => {
      this.questionario = questionario;
    });

  }



  delete(): void  {
    this.questionarioService.deleteQuestionario(this.questionarioService.idDel).subscribe(
      () => this.questionarioService.getQuestionarios({ page: "0", size: "5" }));
  }
}


@Component({
  selector: 'dialog-cancela-triagem-component',
  templateUrl: 'dialog-cancela-triagem-component.html',
})
export class DialogCancelaTriagemComponent implements OnInit{


  questionario: Questionario;
  id: number = this.questionarioService.idDel;
  idDelTriagem: number = this.questionarioService.idDelTriagem

  constructor(private questionarioService: QuestionarioService) { }

  ngOnInit(): void {
    this.questionarioService.readById(this.id).subscribe((questionario) => {
      this.questionario = questionario;
    });
  }



  deleteTriagem(): void  {
    this.questionarioService.deleteTriagem(this.idDelTriagem).subscribe(
      () => this.questionarioService.getQuestionarios({ page: "0", size: "5" }));
    window.location.reload();
  }
}
