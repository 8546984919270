import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { DiaSemana } from './dia-semana.model';

export class Agenda {
  idAgenda?: number;
  idPessoa: number;
  idConsultorio: number;
  idDiaSemana?: number;
  horaInicio?: string;
  horaFim?: string;
}
