import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Questionario } from './questionario.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Psicologo } from '../psicologo/psicologo.model';
import { Consultorio } from '../psicologo/consultorio.model';
import { catchError, map } from 'rxjs/operators';
import { QuestionarioReadComponent } from './questionario-read/questionario-read.component';
import { Horario } from '../horarios/horario.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionarioService {

  private questionario = new Questionario();

  public idDel: number;
  public idDelTriagem: number;
  public idDelHorario: number;
  public idDelConsultorio: number;
  public pessoa: Psicologo;
  public questionarioParaAgendamento: number;
  public isAlterar: boolean = false;

  constructor(private httpClient: HttpClient) { }

  // adicionarPessoa(psicologo: Psicologo ): Observable<any> {
  //   return this.httpClient.post(environment.url + '/pessoa', psicologo);
  // }

  // adicionarEndereco(endereco: Consultorio): Observable<any> {
  //   return this.httpClient.post(environment.url + '/endereco', endereco);
  // }




  adicionarPessoa( pessoa: Psicologo): Observable<any> {
    return this.httpClient.post(environment.url + '/pessoa', pessoa);
  }


  getQuestionarios(request): Observable<Questionario[]> {
    // const tokenString = localStorage.getItem('access_token')
    // const token = JSON.parse(tokenString)
    // const headers = {
    //   'Authorization' : 'Bearer ' + token.access_token,
    //   'Access-Control-Allow-Origin' : 'http://localhost:4200'
    // }
    let params = new HttpParams();
    params = params.append('page', request.page);
    params = params.append('size', request.size);
    return this.httpClient.get<Questionario[]>(environment.url + '/questionario', {params: params} );
  }

  getQuestionariosTerapeuta(id): Observable<Questionario[]> {
    // const tokenString = localStorage.getItem('access_token')
    // const token = JSON.parse(tokenString)
    // const headers = {
    //   'Authorization' : 'Bearer ' + token.access_token,
    //   'Access-Control-Allow-Origin' : 'http://localhost:4200'
    // }
    return this.httpClient.get<Questionario[]>(environment.url + '/questionario/triagem/' + id);
  }

  deleteQuestionario(id): Observable<Questionario[]>{
    return this.httpClient.delete<Questionario[]>(environment.url + '/questionario/' +  id);
  }

  deleteHorario(id): Observable<Horario[]>{
    return this.httpClient.delete<Horario[]>(environment.url + '/agenda/' +  id);
  }

  deleteConsultorio(id): Observable<Consultorio[]>{
    return this.httpClient.delete<Consultorio[]>(environment.url + '/consultorio/' +  id);
  }

  getQuestionarioById(id): Observable<any> {
    return this.httpClient.get(environment.url + '/questionario/' +  id);
  }


  readById(id): Observable<any> {
    return this.httpClient.get<Questionario>(environment.url + '/questionario/' +  id).pipe(
      map((obj) => obj));
  }

  readByIdHorario(id): Observable<any> {
    return this.httpClient.get<Horario>(environment.url + '/agenda/' +  id).pipe(
      map((obj) => obj));
  }

  readByIdconsultorio(id): Observable<any> {
    return this.httpClient.get<Consultorio>(environment.url + '/consultorio/' +  id).pipe(
      map((obj) => obj));
  }

  // getAgendamentoTriagem(id): Observable<any>{
  //   return this.httpClient.get(environment.url + '/triagem/' +  id);
  // }

  deleteTriagem(id): Observable<any>{
    return this.httpClient.delete<any>(environment.url + '/triagem/' +  id);
  }

  getTriagemById(id): Observable<any> {
    return this.httpClient.get(environment.url + '/triagem/' +  id);
  }

  avaliarConcluir(id:number): Observable<any>{
    return this.httpClient.post(environment.url + '/questionario/avaliarConcluir/', id);
  }

  desfazerConcluir(id:number): Observable<any>{
    return this.httpClient.post(environment.url + '/questionario/desfazerConcluir/', id);
  }

  getTerapiaByPessoa(id: number): Observable<any> {
    return this.httpClient.get(environment.url + '/terapia/terapiaByPessoa/' +  id);
  }

}
