import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncaminhamentoService } from './encaminhamento.service';
import { Encaminhamento } from './encaminhamento.model';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Terapia } from '../terapia/terapia.model';

@Component({
  selector: 'app-encaminhamento',
  templateUrl: './encaminhamento.component.html',
  styleUrls: ['./encaminhamento.component.css']
})
export class EncaminhamentoComponent implements OnInit {

  displayedColumns: string[] = ['IdPaciente','Id', 'Paciente', 'Status', 'Triador','Terapeuta', 'Data_Enc', 'Data_Reav'];
  dataSource: MatTableDataSource<Encaminhamento> ;
  public encaminhamento: Encaminhamento;
  encArray: Array<Encaminhamento> = [];
  public terapiaFormGroup: FormGroup;
  public idQuestionario: string;
  terapia: Terapia = new Terapia();

  constructor(private route: ActivatedRoute, private service: EncaminhamentoService, private formBuilder: FormBuilder, private router: Router) {

   }

  ngOnInit(): void {
    this.idQuestionario = this.route.snapshot.paramMap.get('id');
    this.service.geEncaminhamentoById(this.idQuestionario).subscribe(
      resultado => {
        this.encaminhamento = resultado;
        this.encArray.push(this.encaminhamento)
        this.dataSource = new MatTableDataSource<Encaminhamento>(this.encArray);
      });
      this.terapiaFormGroup = this.formBuilder.group({
        terapeuta: ['', Validators.required],
        dataEnc: ['', Validators.required],
      });

  }

  onAdicionarTerapia(): void{
    this.terapia.terapeuta.idPessoa = this.terapiaFormGroup.get('terapeuta').value;
    this.terapia.dataEncaminhamento = this.terapiaFormGroup.value.dataEnc;
    this.terapia.encaminhamento.idEncaminhamento =  this.encaminhamento.idEncaminhamento;
    this.terapia.encaminhamento.id_questionario = this.encaminhamento.id_questionario;
    this.terapia.paciente.idPessoa=this.encaminhamento.idPaciente;
    this.service.addTerapia(this.terapia).subscribe(resultado =>{this.terapia = resultado; });
    this.router.navigate(['listarTerapia']);
  }

}
