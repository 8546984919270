import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Psicologo } from './psicologo.model';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { Consultorio } from './consultorio.model';
import { Endereco } from '../endereco/endereco.model';
import { Horario } from '../horarios/horario.model';
import { DadosPessoais } from '../dados-pessoais/dados-pessoais.model';
import { Escolaridade } from './escolaridade.model';
import { Questionario } from '../questionario/questionario.model';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Agenda } from '../horarios/agenda.model';
import { Cep } from '../endereco/cep.model';

@Injectable({
  providedIn: 'root'
})
export class PsicologoService {

  private psicologos: Psicologo[] = [];
  private listaPsicologoAtualizado = new Subject<Psicologo[]>();
  public pessoa = new Psicologo;
  private endereco: Endereco;
  private horarios: Array<Horario>;
  public consultoriosParaHorario: Array<Consultorio>;
  public terapeuta: boolean = false;
  public consultorio: Consultorio = new Consultorio();
  public consultorios: Array<Consultorio> = new Array<Consultorio>();
  public questionario;
  public idDel: number;
  public concordaTermo: boolean;



  constructor(private httpClient: HttpClient, private router: Router) { }


  adicionarHorario(horarios: Array<Horario>): Observable<any>{
    horarios.forEach(horario => {
      horario.pessoa.idPessoa = this.pessoa.idPessoa;
    });
    let agenda: Agenda = new Agenda;
    let agendas: Array<Agenda>  = new Array<Agenda>();
    horarios.forEach(horario => {

      agenda.horaFim = horario.horaFim;
      agenda.horaInicio = horario.horaInicio;
      agenda.idConsultorio = horario.consultorio.idConsultorio;
      agenda.idDiaSemana = horario.diaSemana.idDiaSemana;
      agenda.idPessoa = horario.pessoa.idPessoa;
      agendas.push(agenda);
    });
    // console.log("agendas");
    // console.log(agendas);
    return this.httpClient.post(environment.url + '/agenda', agendas);
  }


  adicionarHorarioCRUD(horarios: Array<Horario>): Observable<any>{
    let agenda: Agenda = new Agenda;
    let agendas: Array<Agenda>  = new Array<Agenda>();
    horarios.forEach(horario => {
      agenda.horaFim = horario.horaFim;
      agenda.horaInicio = horario.horaInicio;
      agenda.idConsultorio = horario.consultorio.idConsultorio;
      agenda.idDiaSemana = horario.diaSemana.idDiaSemana;
      agenda.idPessoa = horario.pessoa.idPessoa;
      agendas.push(agenda);
    });
    // console.log("agendas");
    // console.log(agendas);
    return this.httpClient.post(environment.url + '/agenda', agendas);
  }

  ehTerapeuta(terapeuta: boolean): void{
    if(terapeuta){
      this.pessoa.terapeuta = true;
    }
  }

  adicionarDadosPessoais(pessoa: Psicologo): void{
    this.pessoa = pessoa;
    this.pessoa.concordo = this.concordaTermo;
    if (this.router.url.includes('/questionario')){
      this.pessoa.terapeuta = false;
    }else{
      this.pessoa.terapeuta = true;
    }
    // console.log(this.pessoa);
  }

  alterarDadosPessoais(pessoa: Psicologo) {
    this.pessoa = pessoa;
    this.pessoa.concordo = this.concordaTermo;
    if(this.router.url.includes('/questionario')){
      this.pessoa.terapeuta = false;
    }else{
      this.pessoa.terapeuta = true;
    }
    // console.log(this.pessoa);
  }

  adicionaEndereco(pessoa: Psicologo): void{
    this.pessoa.idEndereco = pessoa.idEndereco;
    this.pessoa.bairro = pessoa.bairro;
    this.pessoa.cep = pessoa.cep;
    this.pessoa.complemento = pessoa.complemento;
    this.pessoa.cidade = pessoa.cidade;
    this.pessoa.estado = pessoa.estado;
    this.pessoa.logradouro = pessoa.logradouro;
    this.pessoa.numeroR = pessoa.numeroR;
    this.adicionarPsicologo().subscribe(resultado => this.pessoa = resultado);
  }

  alteraEndereco(pessoa: Psicologo): void{
    this.pessoa.bairro = pessoa.bairro;
    this.pessoa.cep = pessoa.cep;
    this.pessoa.complemento = pessoa.complemento;
    this.pessoa.cidade = pessoa.cidade;
    this.pessoa.estado = pessoa.estado;
    this.pessoa.logradouro = pessoa.logradouro;
    this.pessoa.numeroR = pessoa.numeroR;
    this.alterarPsicologo(this.pessoa).subscribe(resultado => this.pessoa = resultado);
  }

  adicionarPsicologo(): Observable<any>{
    // console.log(this.pessoa);
    return this.httpClient.post(environment.url + '/pessoa', this.pessoa);
  }

  adicionarPsicologoLogin(nome: string): Observable<any>{
    this.pessoa.email = nome;
    this.pessoa.nome = "insira seu nome";
    this.pessoa.terapeuta = true;
    return this.httpClient.post(environment.url + '/pessoa', this.pessoa);
  }

  adicionarQuestionario( questionario: Questionario): Observable<any> {
    questionario.idPessoa = this.pessoa.idPessoa;
    // console.log('idPessoa= ' + questionario.idPessoa);
    // console.log('idPessoa do questionario= ' + questionario.idPessoa);
    // console.log(questionario);
    return this.httpClient.post(environment.url + '/questionario', questionario);
  }

  getConsultorios(): Observable<Consultorio[]>{
    return this.httpClient.get<Consultorio[]>(environment.url + '/consultorio');
  }

  getConsultoriosByPsicologo(id: number): Observable<Consultorio[]>{
    return this.httpClient.get<Consultorio[]>(environment.url + '/consultorio/' +  id);
  }

  getConsultoriosPsicologoByCpf(cpf: number): Observable<Consultorio[]>{
    return this.httpClient.get<Consultorio[]>(environment.url + '/consultorio/pessoa/' +  cpf);
  }

  atualizaConsultodeDePsicologo (consultoriosParaHorario): Observable<Array<Consultorio>>{
    return this.consultoriosParaHorario = consultoriosParaHorario;
  }

  addConsultorio(consultorio: Consultorio): Observable<any>{
    return this.httpClient.post(environment.url + '/consultorio', consultorio);
  }

  adicionarConsultorio(consultoriosParaHorario): Observable<any>{
    this.pessoa.consultorios = consultoriosParaHorario;
    return this.httpClient.post(environment.url + '/consultorio/consultorioPessoa', (this.pessoa));
  }

  getHorarios(): Observable<Horario[]>{
    return this.httpClient.get<Horario[]>(environment.url + '/agenda/' + this.pessoa.idPessoa);
  }

  getHorariosCRUD(pessoa: Psicologo): Observable<Horario[]>{
    return this.httpClient.get<Horario[]>(environment.url + '/agenda/' + pessoa.idPessoa);
  }

  getEscolaridade(): Observable<Escolaridade[]>{
    return this.httpClient.get<Escolaridade[]>(environment.url + '/escolaridade');
  }

  alterarPsicologo(pessoa: Psicologo): Observable<any> {
    // console.log(pessoa);
    return this.httpClient.put(environment.url + '/pessoa/alterar/' +  this.pessoa.idPessoa, pessoa);
  }

  alterarQuestionario(questionario): Observable<any> {
    this.questionario = questionario;
    // console.log("alterando questionario");
    // console.log(questionario);
    return this.httpClient.put(environment.url + '/questionario/' +  questionario.idQuestionario, this.questionario);
  }

  getPsicologoById(id: number): Observable<any> {
    // console.log(id);
    return this.httpClient.get(environment.url + '/pessoa/' +  id);
  }

  getPsicologoByEmail(email: string): Observable<any> {
    // console.log(email);
    return this.httpClient.get<any>(environment.url + '/pessoa/email/' +  email);
  }

  getConsultorioByPessoa(pessoa: Psicologo): Observable<Consultorio[]> {
    // console.log(pessoa);
    return this.httpClient.get<Consultorio[]>(environment.url + '/consultorio/' +  pessoa.idPessoa);
  }

  getPsicologos(request): Observable<Psicologo[]> {
    // const tokenString = localStorage.getItem('access_token')
    // const token = JSON.parse(tokenString)
    // const headers = {
    //   'Authorization' : 'Bearer ' + token.access_token,
    //   'Access-Control-Allow-Origin' : 'http://localhost:4200'
    // }
    let params = new HttpParams();
    params = params.append('page', request.page);
    params = params.append('size', request.size);
    return this.httpClient.get<Psicologo[]>(environment.url + '/pessoa', {params: params} );
  }

  getPsicologosEHorario(): Observable<Psicologo[]> {
    return this.httpClient.get<Psicologo[]>(environment.url + '/pessoa/comHorarios');
  }

  deletePsicologo(id): Observable<Psicologo[]>{
    return this.httpClient.delete<Psicologo[]>(environment.url + '/pessoa/' +  id);
  }

  readById(id): Observable<any> {
    return this.httpClient.get<Psicologo>(environment.url + '/pessoa/' +  id).pipe(
      map((obj) => obj));
  }

  verificaCPF(cpf): Observable<Psicologo>{
    return this.httpClient.get<Psicologo>(environment.url + '/pessoa/consulta/' + cpf);
  }

  verificaCEP(cep): Observable<Cep>{
    return this.httpClient.get<Cep>(environment.url + '/endereco/' + cep);
  }

  getDisponibilidade(): Observable<any>{
    return this.httpClient.get<any>(environment.url + '/disponibilidade');
  }

  adicionarAgendamento(agendamentoFormGroup: any) {
    return this.httpClient.post<any>(environment.url + '/triagem', agendamentoFormGroup);
  }

  addConcordoTermoCliente(): void{
    this.concordaTermo = true;
  }

}
