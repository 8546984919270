import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Terapia } from '../terapia/terapia.model';

@Injectable({
  providedIn: 'root'
})
export class EncaminhamentoService {


  constructor(private httpClient: HttpClient) { }

  geEncaminhamentoById(id: string): Observable<any>{
    return this.httpClient.get(environment.url + '/encaminhamento/' +  id);
  }

  addTerapia(terapia: Terapia): Observable<any> {
    return this.httpClient.post(environment.url + '/terapia', terapia);
  }
}
