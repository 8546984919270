import { Triagem } from "../agendamento/triagem.model";
import { Psicologo } from "../psicologo/psicologo.model";

export class Encaminhamento {
  idEncaminhamento:number;
  id_questionario:number;
  idPaciente:number;
  dataEncaminhamento: Date;
  nomePaciente: String;
  dataReavaliacao:Date;
  isEncerrado:Boolean;
  isAvaliadoSegFinal:Boolean;
  segFinalAvaliacao:String;
  nomeTriador:String;
  nomeTerapeuta:String;
  dataTriagem:Date;
  status:String;
  terapeutaList: Array<Psicologo>

}
