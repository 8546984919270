import { Component, OnInit, Output } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { EnderecoComponent } from '../endereco/endereco.component';
import { Consultorio } from '../psicologo/consultorio.model';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { QuestionarioService } from '../questionario/questionario.service';

@Component({
  selector: 'app-consultorios',
  templateUrl: './consultorios.component.html',
  styleUrls: ['./consultorios.component.css']
})
export class ConsultoriosComponent implements OnInit {


  ids: Array<number> = [];
  consultorios: Array<any> = [  ];
  @Output() public consultoriosParaHorario: Array<Consultorio>;
  isHidden: boolean = true;
  public fifthFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  isPsicologoInserir: boolean = true;
  nomeConsultorio: string = ' ';
  ehAdmin: boolean = false;

  usuarioLogado: string;
  roles: string;
  id:number;
  consultorio: Consultorio = new Consultorio();


  public pessoa = new Psicologo;

  estado :Array<{id:number, option:string}> = [
    {id:1, option:"SP"},
    {id:2, option:"RJ"},
    {id:3, option:"MG"},
    {id:4, option:"DF"},
    {id:5, option:"SC"},
    {id:6, option:"BA"}
  ]

  cidade :Array<{id:number, option:string}> = [
    {id:1, option:"São Paulo"},
    {id:2, option:"Vargem Grande Paulista"},
    {id:3, option:"Mauá"},
    {id:4, option:"São Caetano"}
  ]

  constructor(public psicologoService: PsicologoService, private formBuilder: FormBuilder,private route: ActivatedRoute,
    private router: Router, public dialog: MatDialog, private questionarioService: QuestionarioService, private authService: AuthService ) {
    this.fifthFormGroup = this.formBuilder.group({});


   }

   public enderecoComponent: EnderecoComponent;

  ngOnInit(): void {
    this.secondFormGroup = this.formBuilder.group({
      nomeConsultorio: ['', Validators.required],
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      numeroR: ['', Validators.required],
      complemento: ['', Validators.required],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.required],
      privado: [''],
    });
    this.isPsicologoInserirShow();
    this.usuarioLogado = this.authService.getUsuarioAutenticado();
    this.psicologoService.getPsicologoByEmail(this.usuarioLogado).subscribe(resultado =>{
      this.pessoa.idPessoa = resultado.idPessoa;
      if (this.pegaRoles()){
        this.psicologoService.getConsultorios().subscribe(resultado => {this.consultorios = resultado});
      }else{
        	this.psicologoService.getConsultoriosByPsicologo(resultado.idPessoa).subscribe(resultado => {this.consultorios = resultado});
      }
    });


    // this.update();
  }

  isPsicologoInserirShow(){
    if(this.router.url === '/psicologoInserir'){
      this.isPsicologoInserir = true;
    }else{
      this.isPsicologoInserir = false;
    }
  }

  update(){
    this.psicologoService.getConsultoriosByPsicologo(this.pessoa.idPessoa).pipe(
      tap((consultorios) => {
        consultorios.forEach((val, index) => {
          this.fifthFormGroup.addControl(`check-${index}`, new FormControl(false));
         });
      })
      ).subscribe(resultado => this.consultorios = resultado);
  }

  mostrarForm(){
    if (this.isHidden){
      this.isHidden = false;
    }
    else{
      this.isHidden = true;
    }
  }


  verify(checked: boolean, id:number){
    if (checked){
      this.ids.push(id)
    }else{
      const index = this.ids.indexOf(id);
      this.ids.splice(index, 1);
    }
    // console.log(this.ids);
  }

  addOneConsultorio():void{
    this.consultorio = this.secondFormGroup.value;
    this.consultorio.idPessoa = this.pessoa.idPessoa;
    this.psicologoService.addConsultorio(this.consultorio).subscribe();
    this.psicologoService.getConsultorios().pipe(
      tap((consultorios) => {
        consultorios.forEach((val, index) => {
          this.fifthFormGroup.addControl(`check-${index}`, new FormControl(false));
         });
      })
      ).subscribe(q => {this.consultorios = q});
     window.location.reload();
  }

  onAdicionarConsultorio(): void{
    this.consultoriosParaHorario = [];
    for (let id of this.ids) {
      for (let cons of this.consultorios){
        if (cons.idConsultorio == id){
          this.consultoriosParaHorario.push(cons);
        }
      }
    }
    this.psicologoService.adicionarConsultorio(this.consultoriosParaHorario).subscribe();
    // this.psicologoService.getConsultoriosPsicologo().subscribe(resultado => this.consultoriosParaHorario = resultado);
    // console.log(this.consultoriosParaHorario);
    // this.psicologoService.atualizaConsultodeDePsicologo(this.consultoriosParaHorario);
  }

  openDialog(index: number): void {
    this.questionarioService.idDelConsultorio = index;
    this.questionarioService.pessoa= this.pessoa;
    // this.dataSource.data.splice(index,1);
    // window.location.reload();
    const dialogRef = this.dialog.open(DialogConsultorioDeleteComponent, {
      height: '250px',
      width: '400px',});

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      console.log(" ");
    });
  }

  verificaCEP(event: any): void {
    let cep = event.target.value;
    if (cep != null){


   this.psicologoService.verificaCEP(cep).subscribe(resultado => {
     if (resultado){
     this.secondFormGroup.setValue({
      nomeConsultorio: this.nomeConsultorio,
       cep: resultado.cep.replace('-', ''),
       logradouro: resultado.logradouro,
       numeroR: '',
       complemento: '',
       estado: resultado.uf,
       cidade: resultado.localidade,
       bairro: resultado.bairro,
       privado: false
     });
   }else{
     this.secondFormGroup.setValue({
      nomeConsultorio: this.nomeConsultorio,
       cep: resultado.cep.replace('-', ''),
       logradouro: "CEP inválido",
       numeroR: '',
       complemento: '',
       estado: 'CEP inválido',
       cidade: 'CEP inválido',
       bairro: 'CEP inválido',
       privado: false
     });
   }
   }
     );

 }

 }

 salvaNome(event: any): void {
  this.nomeConsultorio = event.target.value;
 }

 pegaRoles(){
  this.roles = this.authService.obterRoles();
  if(this.roles == "ROLE_ADMIN"){
    this.ehAdmin = true;
    return true;
  }
  return false;
}

}



@Component({
  selector: 'dialog-consultorio-delete-component',
  templateUrl: 'dialog-consultorio-delete-component.html',
})
export class DialogConsultorioDeleteComponent implements OnInit{


  consultorio: Consultorio;
  id: number = this.questionarioService.idDelConsultorio;

  constructor(private questionarioService: QuestionarioService, private psicologoService: PsicologoService) { }

  ngOnInit(): void {

  }



  delete(): void  {
    this.questionarioService.deleteConsultorio(this.questionarioService.idDelConsultorio).subscribe(
      () => this.psicologoService.getConsultorioByPessoa(this.questionarioService.pessoa));
    window.location.reload();

}



}
