  <form  [formGroup]="firstFormGroup">

      <mat-form-field class="trinta" >
        <input type="text" matInput placeholder="nome" formControlName="nome" minlength="3" maxlength="15" value="">
        <mat-error *ngIf="firstFormGroup.controls['nome'].invalid">Sobrenome com 3 ou mais letras</mat-error>
      </mat-form-field>
      <mat-form-field class="trinta">
        <input type="text" matInput placeholder="sobrenome" formControlName="sobrenome" minlength="3" maxlength="15">
        <mat-error *ngIf="firstFormGroup.controls['sobrenome'].invalid">Sobrenome com 3 ou mais letras</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres">
        <input type='text' [mask]="getCpfCnpjMask()" matInput placeholder="CPF" formControlName="cpf" (focusout)="existeCpf($event)" pattern="([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})">
        <mat-error *ngIf="firstFormGroup.get('cpf').getError('cpfNotValid')">Digite um cpf válido</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres">
        <input type="number" matInput placeholder="telefone" formControlName="telefone" minlength="3">
        <mat-error *ngIf="firstFormGroup.controls['telefone'].invalid">Digite um telefone válido</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres">
        <input type="email" matInput placeholder="e-mail" formControlName="email" minlength="3">
        <mat-error *ngIf="firstFormGroup.controls['email'].invalid">Digite um e-mail válido</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres" >
        <mat-label>Escolaridade</mat-label>
        <mat-select formControlName="escolaridade">
          <mat-option *ngFor="let esco of escolaridade" [value]="esco.idEscolaridade" >
            {{esco.escolaridade}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="firstFormGroup.controls['escolaridade'].invalid">Escolha uma escolaridade válida</mat-error>
      </mat-form-field>
      <mat-form-field class="trintaEtres">
        <mat-label>Data de Nascimento</mat-label>
        <input matInput [matDatepicker]="dataNasc" formControlName="dataNasc">
        <mat-datepicker-toggle matSuffix [for]="dataNasc"></mat-datepicker-toggle>
        <mat-datepicker #dataNasc></mat-datepicker>
      </mat-form-field>

    <mat-form-field class="trinta" style="visibility: hidden; width: 0px!important;">
      <input type="text" matInput placeholder="idPessoa" formControlName="idPessoa" minlength="3" maxlength="15" style="visibility: hidden; width: 0px!important;">
      <!-- <mat-error *ngIf="firstFormGroup.controls['idPessoa'].invalid">Sobrenome com 3 ou mais letras</mat-error> -->
    </mat-form-field>


  </form>

  <div>
    <button (click)="onAdicionarPessoa()" [disabled]="firstFormGroup.invalid" mat-button matStepperNext>Próximo</button>
  </div>

