<div class="canvas">
  <h2>TERAPIA</h2>



<form  [formGroup]="terapiaFormGroup" (ngSubmit) = "alteraTerapia()">

  <h4 style="margin-top: 40px;">paciente: {{terapia.paciente.nome}}  {{terapia.paciente.sobrenome}}</h4>

  <mat-form-field class="trintaEtres" style="visibility: hidden; width: 0px!important;">
    <input type="text" matInput placeholder="id_terapia" formControlName="id_terapia" minlength="3" maxlength="15" style="visibility: hidden; width: 0px!important;">
  </mat-form-field>

  <mat-form-field class="trintaEtres" style="margin-left: 80px; margin-bottom: 80px; margin-top: 40px;">
    <mat-label>Status</mat-label>
    <mat-select [formControl]="terapiaFormGroup.get('statusTerapiaList')" >
      <mat-option *ngFor="let status of terapia.statusTerapiaList" [value]="status" name="statusTerapiaList">
          {{status}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="trintaEtres" style="margin-left: 80px; margin-bottom: 80px; margin-top: 40px;">
    <mat-label>Terapeutas</mat-label>
    <mat-select formControlName="terapeuta">
      <mat-option *ngFor="let terapeuta of terapia.terapeutas" [value]="terapeuta.idPessoa" name="terapeuta">
        {{terapeuta.nome}} {{terapeuta.sobrenome}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="trintaEtres datepicker">
    <input matInput [matDatepicker]="pickerInicio" formControlName="dataInicioTerapia" name="dataInicioTerapia" class="data">
    <mat-hint>Data Início Terapia</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
    <mat-datepicker #pickerInicio></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="trintaEtres datepicker">
    <input matInput [matDatepicker]="pickerFim" formControlName="dataFimTerapia" name="dataFimTerapia" class="data">
    <mat-hint>Data Fim Terapia</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="pickerFim"></mat-datepicker-toggle>
    <mat-datepicker #pickerFim></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="trintaEtres datepicker">
    <input matInput [matDatepicker]="pickerEstimado" formControlName="dataFimEstimado" name="dataFimEstimado" class="data">
    <mat-hint>Data Fim Estimado Terapia</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="pickerEstimado"></mat-datepicker-toggle>
    <mat-datepicker #pickerEstimado></mat-datepicker>
  </mat-form-field>



  <div class="trintaEtres">
    <button mat-button >Alterar</button>
  </div>


</form>
