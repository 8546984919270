import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoginService } from '../login/login.service';
import { Usuario } from '../login/usuario';
import { Psicologo } from '../psicologo/psicologo.model';
import { PsicologoService } from '../psicologo/psicologo.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login-senha',
  templateUrl: './login-senha.component.html',
  styleUrls: ['./login-senha.component.css']
})
export class LoginSenhaComponent implements OnInit {

  username: string;
  password: string;
  password2: string;
  errors: string[];
  cadastrando: boolean;
  mensagemSucesso: string;
  psicologo: Psicologo;
  usuario: Usuario;
  email: string;
  jwt: string;
  isExpired: boolean = false;

  constructor(private loginService: LoginService, private router: Router,
              private authService: AuthService, private psicologoService: PsicologoService, private route: ActivatedRoute){}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('email')){
      this.jwt = this.route.snapshot.paramMap.get('email');
      const helper = new JwtHelperService();
      this.isExpired = helper.isTokenExpired(this.jwt);
      if ( this.isExpired ){
        this.errors = ['Gere um novo link !'];
      }else{
        const decodedToken = helper.decodeToken(this.jwt);
        // console.log(decodedToken);
        // console.log(decodedToken.sub);
        this.email = decodedToken.sub;
        this.username = this.email;
      }



    }
  }

 onSubmit(){
  this.authService.logar( this.username, this.password )
                  .subscribe( response => {
                    const access_token = JSON.stringify(response);
                    localStorage.setItem('access_token', access_token);

                    this.loginService.getUserByToken(this.username).subscribe(response => {
                      this.usuario = response;
                      this.psicologoService.pessoa.idPessoa = this.usuario.idPessoa;
                      this.router.navigate(['/']).then(() => {
                      window.location.reload()});
                    });
                  }, errorResponse => {
                    this.errors = ['Usuário e/ou senha incorretos'];
                  })
 }

 preparaCadastrar(event){
   event.preventDefault();
   this.cadastrando = true;
 }


 cancelaCadastro(){
   this.cadastrando = false;
   this.mensagemSucesso = null;
   this.errors = [];
   this.username = '';
   this.password = '';
 }

 cadastrar(){
    if ( this.password !== this.password2 ){
      this.errors = ['Senhas não conferem !'];
    }else{
      this.errors = null;
      const usuario: Usuario = new Usuario();
      usuario.email = this.username;
      usuario.senha = this.password;
      usuario.ativo = true;
      usuario.roles = 'USER';

      this.authService.alterar(usuario).subscribe( response => {
      this.mensagemSucesso = 'Senha alterada com sucesso! Efetue o login.';
      this.errors = null;
      }, errorResponse => {
        this.errors = errorResponse.error.errors;
        this.mensagemSucesso = null;
      });
    }
   }


 recuperar(){
  event.preventDefault();
  this.router.navigate(['/recuperar'])
}

voltar(){
  this.router.navigate(['/login'])
}

}
