<div>
  <h2>Agendamento</h2>

<div class="candidato">
  <div class="candidatoTexto"> candidato: <span class="bold">{{pessoa.nome}} {{pessoa.sobrenome}}</span></div>
  <div class="candidatoTexto">disponibilidade:<span class="bold">{{ dia.option }} {{questionario.periodo}}</span></div>

</div>


<!-- <div>
  terapeuta:
  <select  (change)="onFilterTerapeuta($event.target.value)">
    <option *ngFor="let i of disponibilidade" [value]="i.id_terapeuta" >{{i.nome}} {{i.sobrenome}}</option>
 </select>

 dia da semana:
 <select  >
   <option *ngFor="let e of diaSemanaTerapeuta" > {{e.mes.diasSemana.diaSemana}} </option>
</select> -->

<form  [formGroup]="agendamentoFormGroup">

<mat-form-field class="trintaEtres" >
  <mat-label>Terapeuta</mat-label>
  <mat-select formControlName="terapeuta" (selectionChange)="onFilterTerapeuta($event.value)" >
    <mat-option *ngFor="let i of disponibilidade" [value]="i.id_terapeuta" >
      {{i.nome}} {{i.sobrenome}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="agendamentoFormGroup.controls['terapeuta'].invalid">Escolha um terapeuta válido</mat-error>
</mat-form-field>

<mat-form-field class="trintaEtres">
  <mat-label>Dia da semana</mat-label>
  <mat-select formControlName="diaSemana" (selectionChange)="onFilterDiaMes($event.value)" >
    <mat-option *ngFor="let d of diaTerapeuta" [value]="d.id">
      {{d.option}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="agendamentoFormGroup.controls['diaSemana'].invalid">Escolha um dia válido</mat-error>
</mat-form-field>

<mat-form-field class="trintaEtres">
  <mat-label>Dia do mês</mat-label>
  <mat-select formControlName="diaMes" (selectionChange)="onFilterHorarios($event.value)">
    <mat-option *ngFor="let d of diasMes; let i=index"  [value]="d.diasMes">
      {{d.diasMes}}/{{d.mes}}/{{anos[i].ano}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="agendamentoFormGroup.controls['diaMes'].invalid">Escolha um dia válido</mat-error>
</mat-form-field>

<mat-form-field class="trintaEtres">
  <mat-label>Horarios</mat-label>
  <mat-select formControlName="horarios">
    <mat-option *ngFor="let h of horarios" [value]="h.horario" [disabled]="h.preenchido === true">
       {{h.horario | number:'2.0'}}<span *ngIf="!this.semDoisPontos">:</span>{{h.minutos | number:'2.0'}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="agendamentoFormGroup.controls['horarios'].invalid">Escolha um horário válido</mat-error>
</mat-form-field>

</form>

<div class="dosBotoes">
  <div>
    <button *ngIf="isAlterar === false" (click)="onAdicionarAgenda()" [disabled]="agendamentoFormGroup.invalid" mat-button class="botaoVerde">Agendar</button>
  </div>
  <div>
    <button *ngIf="isAlterar === true" (click)="onAdicionarAgenda()" [disabled]="agendamentoFormGroup.invalid" mat-button class="botaoVerde">Alterar</button>
  </div>
  <div>
    <button (click)="onCancelarAlteracao()" [disabled]="agendamentoFormGroup.invalid" mat-button class="botaoVermelho">Cancelar</button>
  </div>
</div>




</div>
