<div class="canvas">
  <h2>Relatório</h2>



<form  [formGroup]="relatorioFormGroup" (ngSubmit) = "alteraRelatorio()">

  <p style="margin-top: 40px;">
    <span class="infoRel"> <span class="infoRelatorio">paciente: </span> <span class="infoRelatorioBold">{{terapia.paciente.nome}} {{terapia.paciente.sobrenome}}</span></span>
    <span class="infoRel"> <span class="infoRelatorio">status: </span> <span class="infoRelatorioBold">{{terapia.statusTerapia}}</span></span>
    <!-- <span class="infoRel"> <span class="infoRelatorio">triad;or: </span> <span class="infoRelatorioBold">{{terapia.encaminhamento.nomeTriador}}</span></span> -->
    <span class="infoRel"> <span class="infoRelatorio">terapeuta: </span> <span class="infoRelatorioBold">{{terapia.terapeuta.nome}} {{terapia.terapeuta.sobrenome}}</span></span>
    <!-- <span class="infoRel"> <span class="infoRelatorio">data triagem: </span> <span class="infoRelatorioBold">{{terapia.encaminhamento.dataTriagem}}</span></span> -->
    <span class="infoRel"> <span class="infoRelatorio"> data encerramento: </span> <span class="infoRelatorioBold">{{terapia.dataFimTerapia}}</span></span>
    <span class="infoRel"> <span class="infoRelatorio"> data fim estimado: </span> <span class="infoRelatorioBold">{{terapia.dataFimEstimado}}</span></span>

  </p>
  <mat-form-field class="trintaEtres" style="visibility: hidden; width: 0px!important;">
    <input type="text" matInput placeholder="id_terapia" formControlName="id_terapia" minlength="3" maxlength="15" style="visibility: hidden; width: 0px!important;">
  </mat-form-field>

  <mat-form-field  appearance="fill" class="cem" style="margin-top: 80px;">
    <mat-label>Relatório: </mat-label>
    <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              name="relatorioAtendimento"
              formControlName="relatorioAtendimento"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="55"></textarea>
  </mat-form-field>



  <div class="trintaEtres">
    <button mat-button >Salvar</button>
  </div>


</form>
